import Vue from 'vue'
import Router from 'vue-router'
import Admin from '../components/AppAdmin.vue'
import Agenda from '../view/AppAgendaGeral.vue'
import AgendaAlunos from '../view/AppAgendaAlunos.vue'
import AlunosLista from '../view/Alunos/AppLista.vue'
import AlunoDetalhes from '@/view/Alunos/AppDetalhes.vue'
import TreinadorDetalhes from '@/view/Treinadores/AppDetalhes.vue'
import UsuarioDetalhes from '@/view/Usuarios/AppDetalhes.vue'
import DadosAluno from '@/components/aluno/DadosAluno.vue'
import DadosPessoais from '@/components/usuario/DadosPessoais.vue'
import AgendaAluno from '@/components/aluno/AgendaAluno.vue'
import ContratoAluno from '@/components/aluno/ContratoAluno.vue'
import FinanceiroAluno from '@/components/aluno/FinanceiroAluno.vue'
import AlunosCadastro from '../view/Alunos/AppCadastro.vue'
import AlunosContrato from '../view/Alunos/AppContrato.vue'
import AlunosPagamentos from '../view/Alunos/AppPagamentos.vue' 
import AlunosAgenda from '../view/Alunos/AppAgenda.vue'
import AlunosAnamnese from '../view/Alunos/AppAnamnese.vue'
import AlunosAvaliacao from '../view/Alunos/AppAvaliacao.vue'
import AlunosAvaliacaoLista from '../view/Alunos/AppAvaliacaoLista.vue'
import Dashboard from '../components/AppDashboard.vue'
import Aniversariantes from '../components/aniversariantes/AppAniversariantes.vue'
import Contratos from '../components/contratos/AppContratos.vue'
import Dados from '../components/dados/AppDados.vue'
import Login from '../components/AppLogin.vue'
import UsuariosLista from '../view/Usuarios/AppLista.vue'
// import UsuariosCadastro from '../view/Usuarios/AppCadastro.vue'
import TreinadoresLista from '../view/Treinadores/AppLista.vue'
import TreinadoresCadastro from '../view/Treinadores/AppCadastro.vue'
import TreinadorAgenda from '@/components/treinador/TreinadorAgenda.vue'
import TreinadorAlunos from '@/components/treinador/TreinadorAlunos.vue'
import DadosTreinador from '@/components/treinador/DadosTreinador.vue'
import Teste from '../view/AppTeste.vue'
import TreinadoresInicial from '../view/Treinadores/AppInicial.vue'
// import TreinadorAlunos from '../view/Treinadores/AppAlunos.vue'
// import TreinadorAgenda from '../view/Treinadores/AppAgenda.vue'
import RodaSaude from '../components/AppRodaSaude.vue'
import Cadastro from '../view/AppCadastroUsuario.vue'
import CadastroRelacionamento from "../view/AppCadastroRelacionamento.vue"
import CadastroAluno from '../view/AppCadastroAluno.vue'
import CadastroTreinador from '../view/AppCadastroTreinador.vue'
import CadastroAgendaAluno from '../view/AppCadastroAgendaAluno.vue'
import CadastroContratoAluno from '../view/AppCadastroContrato.vue'
import ImportarPagamentos from '../view/AppImportaPagamentos.vue'
import Ranking from '../view/AppRanking.vue'
import RankingAcademia from '../view/AppRankingAcademia.vue'
import RankingEstrelas from '../view/AppRankingEstrelas.vue'
import DashTreinador from '../view/AppDashTreinador.vue'
import GestaoFinanceira from '../view/Financeiro/AppFinanceiro.vue'
import ListaFinanceira from '../view/Financeiro/ListaFinanceiro.vue'
import Relatorios from '../view/AppRelatorios.vue'
import VamoMexeLista from '../view/VamoMexe/AppLista.vue'
import VamoMexeCadastro from '../view/VamoMexe/AppCadastro.vue'
import ConnectUsuarios from '../view/Connect/Usuarios/AppLista.vue'
import Cobrancas from '../view/Cobrancas/ListaCobrancas.vue'
import NotasFiscais from '../view/NotasFiscais/ListaNotasFiscais.vue'

Vue.use(Router)

export default new Router({
  mode: 'history', // Configura o modo de histórico
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      props: {}
    },
    {
      path: '/agenda/:treinadorId',
      name: 'agenda',
      component: Agenda,
      props: {}
    },
    {
      path: '/aniversariantes',
      name: 'aniversariantes',
      component: Aniversariantes
    },
    {
      path: '/contratos',
      name: 'contratos',
      component: Contratos,
    },
    {
      path: '/agenda-alunos/:treinadorId',
      name: 'agenda-alunos',
      component: AgendaAlunos,
      props: {}
    },
    {
      path: '/dados',
      name: 'dados',
      component: Dados,
      props: {}
    },
    {
      path: '/roda-saude',
      name: 'rodasaude',
      component: RodaSaude,
      props: {},
    },
    {
      path: '/treinador-inicial',
      name: 'treinadorinicial',
      component: TreinadoresInicial,
      props: {}
    },
    {
      path: '/treinador-alunos',
      name: 'treinadoralunos',
      component: TreinadorAlunos,
      props: {}
    },
    {
      path: '/treinador-agenda/:id',
      name: 'treinadoragenda',
      component: TreinadorAgenda,
      props: {}
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: UsuariosLista
    },
    {
      path: '/cadastrousuarios/:tipo',
      name: 'cadastrousuarios',
      component: DadosPessoais
    },
    {
      path: '/cadastro/:id/:tipo',
      name: 'cadastro',
      component: Cadastro
    },
    {
      path: '/cadastroaluno',
      name: 'cadastroaluno',
      component: CadastroAluno
    },    
    {
      path: '/cadastroagendaaluno',
      name: 'cadastroagendaaluno',
      component: CadastroAgendaAluno
    },
    {
      path: '/cadastrocontratoaluno',
      name: 'cadastrocontratoaluno',
      component: CadastroContratoAluno
    },
    {
      path: '/cadastrorelacionamento',
      name: 'cadastrorelacionamento',
      component: CadastroRelacionamento
    },
    {
      path: '/cadastrotreinador',
      name: 'cadastrotreinador',
      component: CadastroTreinador
    },
    {
      path: '/alunos',
      name: 'alunos',
      component: AlunosLista
    },
    {
      path: '/alunos-desafio',
      name: 'alunos-desafio',
      component: AlunosLista,
    },
    {
      path: '/cadastroalunos',
      name: 'cadastroalunos',
      component: AlunosCadastro
    },    
    {
      path: '/contrato',
      name: 'contrato',
      component: AlunosContrato
    },
    {
      path: '/pagamentos',
      name: 'pagamentos',
      component: AlunosPagamentos
    },
    {
      path: '/agendaaluno',
      name: 'agendaaluno',
      component: AlunosAgenda
    },
    {
      path: '/anamnese',
      name: 'anamnese',
      component: AlunosAnamnese
    },
    {
      path: '/lista-avaliacao',
      name: 'lista-avaliacao',
      component: AlunosAvaliacaoLista
    },
    {
      path: '/avaliacao',
      name: 'avaliacao',
      component: AlunosAvaliacao
    },
    {
      path: '/treinadores',
      name: 'treinadores',
      component: TreinadoresLista
    },
    {
      path: '/cadastrotreinadores',
      name: 'cadastrotreinadores',
      component: TreinadoresCadastro
    },
    {
      path: '/teste',
      name: 'teste',
      component: Teste
    },
    {
      path: '/importar',
      name: 'importar',
      component: ImportarPagamentos
    },
    {
      path: '/ranking',
      name: 'ranking',
      component: Ranking
    },
    {
      path: '/ranking-academia/:id',
      name: 'rankingacademia',
      component: RankingAcademia
    },
    {
      path: '/ranking-estrelas',
      name: 'rankingestrelas',
      component: RankingEstrelas
    },
    {
      path: '/treinador',
      name: 'treinador',
      component: DashTreinador
    },
    {
      path: '/lista-financeira',
      name: 'listafinanceira',
      component: ListaFinanceira
    },
    {
      path: '/gestao-financeira/:dre_id',
      name: 'gestaofinanceira',
      component: GestaoFinanceira
    },

    {
      path: '/relatorios',
      name: 'relatorios',
      component: Relatorios
    },

    {
      path: '/lista-vamomexe',
      name: 'listavamomexe',
      component: VamoMexeLista
    },

    {
      path: '/cadastro-vamomexe',
      name: 'cadastrovamomexe',
      component: VamoMexeCadastro
    },

    {
      path: '/connect/usuarios',
      name: 'connect_usuarios',
      component: ConnectUsuarios
    },

    {
      path: '/lista-cobrancas',
      name: 'listacobrancas',
      component: Cobrancas
    },

    {
      path: '/notas-fiscais',
      name: 'notas',
      component: NotasFiscais
    },
    { 
      path: '/aluno/:id', 
      name: 'alunodetalhes',
      component: AlunoDetalhes,
      children: [
        { path: 'contrato', component: ContratoAluno },
        { path: 'financeiro', component: FinanceiroAluno },
        { path: 'agenda', component: AgendaAluno },
        { path: 'dados-pessoais', component: DadosPessoais },
        { path: 'dados-academia', component: DadosAluno },
      ],
      props: true
    },

    { 
      path: '/treinador/:id', 
      name: 'treinadordetalhes',
      component: TreinadorDetalhes,
      children: [
        { path: 'agenda', component: TreinadorAgenda },
        { path: 'alunos', component: TreinadorAlunos },
        { path: 'dados-pessoais', component: DadosPessoais },
        { path: 'dados-treinador', component: DadosTreinador },
      ],
      props: true
    },

    { 
      path: '/usuario/:id', 
      name: 'usuariodetalhes',
      component: UsuarioDetalhes,
      children: [
        { path: ':tipo/:nome/dados-pessoais', component: DadosPessoais },
        { path: ':tipo/:nome/dados-academia', component: DadosAluno },
        { path: ':tipo/:nome/dados-treinador', component: DadosTreinador },
      ],
      props: true
    }
  ]
})