import axios from "axios"

// const baseURL = process.env.VUE_APP_API_URL;
// console.log(`Rodando em modo: ${process.env.NODE_ENV}, API: ${baseURL}`);

const baseURL ="https://api.icaro.fit/api/v1/";
  // const baseURL = "http://localhost:8000/api/v1/";
const api = axios.create({  
  baseURL: baseURL,
  // baseURL: 'https://back-icaro-test-erwx.onrender.com/api/v1/',
  headers: {
    Accept: "application/json",
    Content: "application/json",
    "content-type": "application/json",
  },
})

export default api