<template>   
  <div class="calendario-semanal" style="overflow-x: auto;">
    <div class="filtro">
  <label for="filtro-nome">Filtrar por treinador:</label>
  <input id="filtro-nome" v-model="filtroNome" type="text" placeholder="Digite um nome" class="input-filtro">

  <label for="select-nome">Selecione o treinador:</label>
  <select id="select-nome" v-model="selectedNome" @change="filterBySelectedNome" class="select-filtro">
    <option value="" disabled>Selecione um nome</option>
    <option value="">Todos</option>
    <option v-for="name in uniqueNames" :key="name" :value="name">
      {{ name }}
    </option>
  </select>

  <label for="filtro-alunos">Por número:</label>
  <input id="filtro-alunos" v-model.number="filtroAlunos" type="number" placeholder="Número de alunos" class="input-filtro">

  <button @click="limparFiltro" class="botao-limpar">Limpar</button>
</div>
      <table>
          <thead>
              <tr>
                  <th style="background-color: white;"></th>
                  <th v-for="dia in dias" :key="dia">
                      <span :class="{ 'hoje': isToday(dia) }">{{ dia }}</span>
                  </th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="horario in horariosVisiveis" :key="horario">
                  <td style="width: 50px;">{{ horario.substring(0,2) + 'h' }}</td>
                  <td style="min-width: 3px;" v-for="dia in dias" :key="dia">
                      <div v-for="evento in getEventosParaDia(dia, horario)" :key="evento.id" class="d-inline-block ma-2">
                          <v-badge :content="evento.alunos > 0 ? evento.alunos : '0'" :title="evento.nome" overlap bordered :color="badgeColor(evento.alunos)" class="custom-badge">
                              <v-avatar size="40" color="orange" v-if="evento.experiencia==false"
                                  @click="abreModal(evento.nome, dia, horario, evento.dia_semana, evento.treinador_id)">
                                  <v-img :src="evento.avatar" v-if="evento.avatar"></v-img>
                                  <span v-else>{{ inicialNome(evento.nome) }}</span>
                              </v-avatar>
                          </v-badge>
                          <!-- Segundo badge (por exemplo, status do evento) -->
                          <v-badge v-if="evento.vamomexe" 
                            :content="'+' + evento.vamomexe" 
                            color="blue" 
                            class="custom-badge"
                            bottom
                            overlap bordered
                          >
      
                            <!-- <v-icon v-if="evento.nome !== 'ativo'">mdi-check-circle</v-icon>
                            <v-icon v-else>mdi-close-circle</v-icon> -->
                          </v-badge>
                          <br/>
                          <span style="font-size: 12px;">{{ formataNome(evento.nome) }}</span>                            
                      </div>                
                  </td>
              </tr>
              <v-dialog v-model="dialog" max-width="500px">
                  <v-card>
                      <v-toolbar color="primary" dark>{{ titulo }}</v-toolbar>
                      <v-card-text>
                          <div v-for="aluno in alunos" :key="aluno.id" class="ma-2">
                              {{ aluno.nome }}
                          </div>                        
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        
                          <v-btn text color="success" outlined @click="abreVamoMexe(titulo)">Listar VamoMexe!</v-btn>
                          <v-btn text color="warning" outlined @click="abreVamoMexeCadastrar(titulo)">CadastrarVamoMexe!</v-btn>
                          <v-btn text color="primary" outlined @click="fechaModal('alunos')">Fechar</v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
              <v-dialog v-model="dialogVamoMexe" max-width="500px">
                  <v-card>
                      <v-toolbar color="primary" dark>{{ titulo }}</v-toolbar>
                      <v-divider></v-divider>
  
                      <v-list v-list v-if="experiencias.length > 0">
                        <v-list-item v-for="(item, index) in experiencias" :key="index">
                          <v-list-item-content>
                            <v-list-item-title class="mb-2">
                              <v-row>
                                <v-col cols=9>
                                  <v-text-field
                                          v-model="item.nome" 
                                          label="Nome" 
                                          color="#FF562B"
                                          @change="(newValue) => atualizarStatus(item.id, newValue, 'nome')"
                                        >
                                      </v-text-field>
                                </v-col>
                               
                                <v-col>
                                  <v-select
                                    v-model="item.horario"
                                    :items="hours"
                                    item-value="value"
                                    item-text="text"
                                     @change="(newValue) => atualizarStatus(item.id, newValue, 'horario')"
                                  ></v-select>

                                </v-col>
                              </v-row>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-row>
                                <v-col>
                                   <v-text-field
                                          
                                          v-model="item.telefone" 
                                          label="Celular" 
                                          color="#FF562B"
                                          
                                          v-mask="'(##)# ####-####'"
                                          @change="(newValue) => atualizarStatus(item.id, newValue, 'telefone')"

                                        >
                                   </v-text-field>
                                </v-col>
                                <v-col>
                                <DatePicker 
                                v-model="(item.data).split('T')[0]" label="Data" :outlined-option="false" :allowed-week-day="item.dia_semana" :extra-item="item.id" @change="onDateChange"/>

                                </v-col>
                                <v-col>
                                      <v-select
                                    v-model="item.status"
                                    :items="statusVamoMexe"
                                    item-value="value"
                                    item-text="text"
                                    @change="(newValue) => atualizarStatus(item.id, newValue, 'status')"
                                  ></v-select>
                                </v-col>
                              </v-row>
                               
                              
                            </v-list-item-subtitle>
                        <v-divider v-if="index !== alunos.length - 1 || index === 1"></v-divider>

                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <!-- Mensagem quando a lista estiver vazia -->
                      <v-alert v-else type="info" class="ma-4" prominent>
                        Nenhum experiência cadastrada.
                      </v-alert>
                     <v-card-actions class="justify-end">
                        
                          <v-btn text color="warning" outlined @click="abreVamoMexeCadastrar(titulo)">Cadastrar!</v-btn>
                          <v-btn text color="primary" outlined @click="fechaModal('vamoMexe')">Fechar</v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
              <v-dialog v-model="dialogVamoMexeCadastrar" max-width="500px">
                  <v-card>
                      <v-toolbar color="primary" dark>{{ titulo }}</v-toolbar>
                      <v-card-text>
                        <ValidationObserver ref="alterarsatus" #default="{ handleSubmit }">
                                    <v-form @keyup.enter="handleSubmit(salvarVamoMexe)" @submit.prevent="handleSubmit(salvarVamoMexe)">
                                    <v-card-text>
                                      <ValidationProvider
                                          #default="{ errors }"
                                          :rules="{ required: true }"
                                          name="nome"                            
                                        >
                                      <v-text-field
                                          
                                          v-model="nome_vamo_mexe" 
                                          label="Nome" 
                                          color="#FF562B"
                                          outlined
                                          :error-messages="errors[0]"
                                        >
                                      </v-text-field>
                                      </ValidationProvider>

                                      <ValidationProvider
                                          #default="{ errors }"
                                          :rules="{ required: true }"
                                          name="telefone"                            
                                        >
                                      <v-text-field
                                          
                                          v-model="telefone_vamo_mexe" 
                                          label="Celular" 
                                          color="#FF562B"
                                          outlined
                                          v-mask="'(##)# ####-####'"
                                          :error-messages="errors[0]"
                                        >
                                      </v-text-field>
                                      </ValidationProvider>
                                   
                                      <template>
                                      <div>
                                        <DatePicker v-model="data_vamo_mexe" label="Data do Evento" :allowed-week-day="dia_semana_vamo_mexe-1" />
                                      </div>
                                    </template>
                                      

                                      <!-- <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="horaSelecionada"
                                        label="Selecione a hora"
                                        prepend-icon="mdi-clock"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template> -->

                                    <!-- <v-card>
                                      <v-time-picker v-model="horaSelecionada" format="24hr" full-width></v-time-picker>
                                      
                                    </v-card> -->

                                      
                                    </v-card-text>
                                    <v-card-actions>
                                          <v-btn color="#FF562B" width="" dark type="submit" class="pa-2"  
                                            >Salvar<v-icon class="">mdi-content-save</v-icon>
                                          </v-btn>
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" outlined @click="fechaModal('vamoMexeCadastrar')">Fechar</v-btn>
                                    </v-card-actions>
                                    </v-form>
                                  </ValidationObserver>        
                      </v-card-text>
                     
                  </v-card>
              </v-dialog>
          </tbody>
      </table>        
  </div>
</template>

<script>
import _utils from '../utils/utils.js'
import _treinador from '../services/treinador/treinador-servico.js'
import _alunos from '../services/aluno/aluno-servico.js'
import statusVamoMexe from '../models/tipo-vamo-mexe'
import DatePicker from '../components/DatePicker.vue'

export default {        
  components: { DatePicker },
    data() {
      return {
          hours: [],
          filtroNome: "",
          filtroAlunos: null, // Filtro por número de alunos
          selectedNome: "", // Novo modelo para o nome selecionado
          dias: ["Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
          horarios: [
              "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00",
              "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00",
          ],
          eventos: [],
          dialog: false,
          dialogVamoMexe: false,
          dialogVamoMexeCadastrar: false,
          titulo: "",
          alunos: [],
          nome_vamo_mexe: "",
          telefone_vamo_mexe: "",
          data_vamo_mexe: "",
          vmTreinadorId: "",
          experiencias: [],
          statusVamoMexe: statusVamoMexe,
          statusVm: "",
          horaSelecionada: "",
          dia_semana_vamo_mexe: 0,
          vamoMexe: [],
          dataVamoMexe: null,
          menuDatePickerI: false,
          ativarDatePicker: null,
      };
  },
  async mounted() {
      this.eventos = await _treinador.listarAgendaGeral()
      if(this.vmTreinadorId) this.hours = await this.generateHourOptions(this.dia_semana_vamo_mexe);
  },

  watch: {
    dia_semana_vamo_mexe(newValue) {
      this.generateHourOptions(newValue);
    },
  },


  computed: {
      uniqueNames() {
          const namesSet = new Set(this.eventos.map(evento => evento.nome));
          return Array.from(namesSet).sort();
      },

      diaSemana() {
        return this.dia_semana_vamo_mexe - 1
      },
      eventosFiltrados() {
            return (dia, horario) => {
                return this.getEventosParaDia(dia, horario).filter(evento => {
                    const matchNome = this.filtroNome ? evento.nome.toLowerCase().includes(this.filtroNome.toLowerCase()) : true;
                    const matchTreinador = this.selectedNome ? evento.nome === this.selectedNome : true;
                    const matchAlunos = this.filtroAlunos !== null ? evento.alunos >= this.filtroAlunos : true;

                    return matchNome && matchTreinador && matchAlunos;
                });
            };
        },
        temEventosVisiveis() {
            return horario => {
                return this.dias.some(dia => this.eventosFiltrados(dia, horario).length >= 0);
            };
        },

        horariosVisiveis() {
          return this.horarios.filter(horario => this.temEventosVisiveis(horario));
        }
    },
  methods: {
    async generateHourOptions(diaSemana) {
      try {
        const response = await _treinador.agendaCompleta(this.vmTreinadorId)
        const horarios = response[0].horarios
        this.hours = horarios[diaSemana]
        // return horarios[diaSemana]
      } catch (error) {
        console.error(error);
      }
    },
        ordenarEventos() {
          const nomesUnicos = Array.from(new Set(this.eventos.map(evento => evento.nome))); // Cria um array de nomes únicos
          return nomesUnicos.sort();
      },
      getFormataData(date) {
          return _utils.formatarDataTela(date)
        },

      getEventosParaDia(dia, horario) {
          const events =  this.eventos.filter(evento => 
              evento.dia === dia && 
              evento.horario === horario &&
              (this.filtroNome === "" || evento.nome.toLowerCase().includes(this.filtroNome.toLowerCase())) &&
              (this.selectedNome === "" || evento.nome === this.selectedNome) && // Filtro do select
              (this.filtroAlunos === null || evento.alunos === this.filtroAlunos) // Filtro por número de alunos
           // Filtra pelo nome selecionado
          );
          return events
      },
      isToday(dia) {
          const hoje = new Date().toLocaleString('pt-BR', { weekday: 'long' }).split('-')[0].trim();        
          return dia.toLowerCase() === hoje.toLowerCase()
      },
      formataNome(nome){
          return _utils.formataNomeCompleto(nome)            
      },
      inicialNome(nome){                        
          return nome[0]
      },
      async abreModal(nome, dia, horario, dia_semana, treinador){
          this.titulo = nome + " | " + dia + " | " + horario
          this.vmTreinadorId = treinador
          this.dia_semana_vamo_mexe = dia_semana
          this.horaSelecionada = horario

          this.dialog = true
          this.alunos = await this.buscaAlunos(treinador, dia_semana, horario)            
      },
      async abreVamoMexe(nome){
          this.titulo = nome + " | Vamo Mexe! Lista" 
          this.dialog = false
          this.dialogVamoMexe = true
          this.experiencias = await this.buscaExperiencia(this.vmTreinadorId, this.dia_semana_vamo_mexe, this.horaSelecionada)
               
      },
      async abreVamoMexeCadastrar(nome){
          // this.limparFormVamoMexe()
          this.titulo = nome + " | Vamo Mexe! Cadastrar" 
          this.dialog = false
          this.dialogVamoMexe = false
          this.dialogVamoMexeCadastrar = true
               
      },
      buscaExperiencia(treinadorId, diaSemana, horario){            
          return _treinador.buscarExperiencia(treinadorId, diaSemana, horario)
      },
      buscaAlunos(id, dia, horario) {            
          return _alunos.buscarAlunosTreinadorDia(id, dia, horario)
      },
      fechaModal(data) {
        switch (data) {
          case 'alunos':
            this.dialog = false
            this.titulo = ""
            this.alunos = []
            break;

          case 'vamoMexeCadastrar':
            this.dialogVamoMexeCadastrar = false
            break;
        
          default:
            this.dialogVamoMexe = false
            break;
        }
          
      },

      formatarData(data) {
        return data ? data.split('T')[0] : ''; // Garante que exiba apenas YYYY-MM-DD
      },

      onDateChange(obj) {
        this.atualizarStatus(obj.extra, obj.data, 'data');
      },

      async atualizarStatus(id, value, column) {
        try {
              this.vamoMexe = {
                [column]: value
              }
              await _treinador.atualizarStatusVamoMexe(id, this.vamoMexe, column); // Aguarda a requisição

              this.dialogVamoMexeCadastrar = false;
              this.snackbarColor = 'success';
              this.snackbarText = 'Vamo Mexe cadastrado com sucesso!';
              this.show = true;

              // ⬇️ Atualiza os eventos sem recarregar a página
              this.eventos = await _treinador.listarAgendaGeral();
              this.experiencias = await this.buscaExperiencia(this.vmTreinadorId, this.dia_semana_vamo_mexe, this.horaSelecionada)
              setTimeout(() => {
                this.show = false;
                this.limparFormVamoMexe()
              }, 3000);
            } catch (error) {
              console.error(error);
            }
      },
   
      async salvarVamoMexe() {
        this.vamoMexe = {
          nome: this.nome_vamo_mexe,
          data: this.data_vamo_mexe,
          telefone: this.telefone_vamo_mexe,
          dia_semana: this.dia_semana_vamo_mexe,
          horario:  this.horaSelecionada,
          treinador_id: this.vmTreinadorId,
          status: 1
        }
        try {
              await _treinador.cadastrarVamoMexe(this.vamoMexe); // Aguarda a requisição

              this.dialogVamoMexeCadastrar = false;
              this.snackbarColor = 'success';
              this.snackbarText = 'Vamo Mexe cadastrado com sucesso!';
              this.show = true;

              // ⬇️ Atualiza os eventos sem recarregar a página
              this.eventos = await _treinador.listarAgendaGeral();

              setTimeout(() => {
                this.show = false;
                this.limparFormVamoMexe()
              }, 3000);
            } catch (error) {
              console.error(error);
            }
      },
      limparFormVamoMexe() {
        this.nome_vamo_mexe = '';
        this.data_vamo_mexe = '';
        this.telefone_vamo_mexe = '';
      },

      badgeColor(alunos) {
          if (alunos >= 1 && alunos <= 5) {
              return 'green'; // Cor verde para até 5 alunos
          } else if (alunos === 6) {
              return 'yellow'; // Cor amarela para exatamente 6 alunos
          } else if (alunos === 0){
            return '#999'
          } else if (alunos > 6) {
              return 'red'; // Cor vermelha para mais de 6 alunos
          }
      },
      filterBySelectedNome() {
          // A lógica de filtragem é realizada na computed 'getEventosParaDia'
      },

      limparFiltro() {
        this.filtroNome = "";
        this.selectedNome = ""// Reseta o filtro para uma string vazia
        this.filtroAlunos = null
    },
  },
};
</script>

<style scoped>
.calendario-semanal {
  font-family: Arial, sans-serif;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table th {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  padding: 10px;
}

table th.hoje {
  background-color: #ffcc00;
}

table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.evento {
  background-color: #007bff;
  color: #fff;
  padding: 5px;
  margin: 5px 0;
  cursor: pointer;
}

.hoje {
  color: #ffcc00;
}

.filtro {
  display: flex;
  flex-direction: row; /* Mantém os elementos em linha */
  gap: 15px; /* Espaçamento entre os elementos */
  align-items: center; /* Alinha os itens verticalmente ao centro */
  padding: 15px; /* Espaçamento interno */
  background-color: #f8f9fa; /* Cor de fundo clara */
  border: 1px solid #ced4da; /* Borda leve */
  border-radius: 8px; /* Bordas arredondadas */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve */
  flex-wrap: wrap; /* Permite que os itens quebrem em novas linhas em telas menores */
}

.input-filtro, .select-filtro {
  padding: 10px; /* Preenchimento interno */
  border: 1px solid #ced4da; /* Borda leve */
  border-radius: 4px; /* Bordas arredondadas */
  transition: border-color 0.3s; /* Transição para efeito de foco */
  font-size: 14px; /* Tamanho da fonte */
  min-width: 120px; /* Largura mínima para inputs e selects */
  flex-grow: 1; /* Permite que os inputs ocupem espaço disponível */
}

.botao-limpar {
  padding: 10px 15px; /* Preenchimento interno do botão */
  background-color: #007BFF; /* Cor de fundo do botão */
  color: white; /* Cor do texto do botão */
  border: none; /* Remove a borda padrão */
  border-radius: 4px; /* Bordas arredondadas */
  cursor: pointer; /* Cursor como mão ao passar o mouse */
  transition: background-color 0.3s; /* Transição para efeito de hover */
  font-size: 14px; /* Tamanho da fonte do botão */
}

.botao-limpar:hover {
  background-color: #0056b3; /* Cor do botão ao passar o mouse */
}

/* Estilo da tabela */
table {
  width: 100%; /* A tabela ocupa 100% da largura do contêiner */
  border-collapse: collapse; /* Remove espaços entre células */
  margin-top: 15px; /* Margem superior para espaçamento */
}

th, td {
  border: 1px solid #ced4da; /* Borda leve para células */
  padding: 8px; /* Espaçamento interno */
  text-align: left; /* Alinhamento do texto à esquerda */
  font-size: 14px; /* Tamanho da fonte */
}

@media (max-width: 768px) {
  .filtro {
    flex-direction: column; /* Muda para coluna em telas menores */
    align-items: flex-start; /* Alinha os itens à esquerda */
  }

  .input-filtro, .select-filtro {
    width: 100%; /* Faz com que os inputs e selects ocupem toda a largura disponível */
  }
}

/* Media query específica para orientação horizontal em dispositivos móveis */
@media (max-width: 768px) and (orientation: landscape) {
  /* Ajuste específico para o campo de número de alunos */
  #filtro-alunos {
    max-width: 150px; /* Largura máxima para o campo de número */
    flex-grow: 0; /* Impede que o campo ocupe espaço extra */
    flex-basis: auto; /* Ajusta a base flexível para o tamanho automático */
  }
}


::v-deep(.v-badge__badge) {
  color: white; /* Cor da fonte */
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.7); /* Sombra no texto */
  font-weight: bold; /* Fonte em negrito */
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5); Sombra ao redor do badge */
}

@media (max-width: 768px) {
table {
      font-size: 12px;
  }
}
</style>