<template>
  <v-card class="pa-4 d-flex flex-column align-end mt-5 ml-2" elevation="1">
    <!-- Card menor no canto superior esquerdo -->
    <v-avatar class="pa-3" :color="corAvatar" size="48" style="position: absolute; top: -12px; left: 10px;">
      <v-icon size="28" >{{ icon }}</v-icon>
    </v-avatar>

    <!-- Número principal -->
    <div class="text-h4 font-weight-bold">
      {{ formattedValue }}
    </div>

    <!-- Linha divisória -->
    <v-divider class="my-2"></v-divider>

    <!-- Texto explicativo -->
    <div class="text-caption text-grey-darken-1">
      {{ text }}
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    value: [String, Number], // Pode ser string ou número
    icon: String,
    text: String,
    corAvatar: String
  },
  computed: {
    formattedValue() {
      if (typeof this.value === "number") {
         return Number.isInteger(this.value)
          ? this.value.toLocaleString("pt-BR") // Inteiro → 1.000
          : this.value.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Decimal → 1.000,00
      }
      return this.value; // Se não for número, retorna o valor original
    }
  }
};
</script>


