import axios from "axios"
// const baseURL = process.env.VUE_APP_FERRAMENTAS_API_URL;
const baseURL = 'https://ferramentas.icaro.fit/ferramentas/';
console.log(`Rodando em modo: ${process.env.NODE_ENV}, API: ${baseURL}`);
const api = axios.create({  
  baseURL: baseURL,      
  headers: {
    Accept: "application/json",
    Content: "application/json",
    "content-type": "application/json",
  },
})

export default api