<template>
  <v-container fluid >
    <!-- <v-toolbar color="orange" dense>
      <v-toolbar-title>Dados do Aluno </v-toolbar-title>
    </v-toolbar> -->
    <v-divider></v-divider>
    <AppConfirma :dialogs="dialogs" @resposta="getResposta"></AppConfirma>    
    <AppAlerta :color="snackbarColor" :text="snackbarText" v-if="show"/>
    <v-spacer class="mt-4"></v-spacer>

    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-card >
          <ValidationObserver ref="form" #default="{ handleSubmit }">
            <v-form
              @keyup.enter="handleSubmit(salvar)"
              @submit.prevent="handleSubmit(salvar)"
            >
              <v-card-text>
                <v-row>
                  <v-col col="6" xs="12" sm="6">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="aluno"
                    >
                      <v-combobox
                        v-model="pessoa_aluno"
                        :items="usuarios"
                        label="Aluno"
                        :error-messages="errors[0]"
                        item-text="nome"
                        item-value="id"
                        outlined
                        :disabled="pessoaDesativada"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col col="6" xs="12" sm="6">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="treinador"
                    >
                      <v-select
                        v-model="treinador"
                        :items="treinadores_ativos"
                        label="Treinador"
                        :error-messages="errors[0]"
                        item-text="nome"
                        item-value="id"
                        outlined
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col="12" md="6" xl="6">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="Unidade"
                    >
                      <v-select
                        v-model="academia"
                        :items="academias"
                        label="Unidade"
                        :error-messages="errors[0]"
                        item-text="nome"
                        item-value="id"
                        outlined
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col col="12" md="6" xl="6">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="Produto"
                    >
                      <v-select
                        v-model="produto"
                        :items="produtos"
                        label="Produto"
                        :error-messages="errors[0]"                        
                        outlined
                      />
                    </ValidationProvider>
                  </v-col>
                  </v-row>
                  <v-row>
                      <v-col col="3" md="3" xl="3">
                        <ValidationProvider
                          #default="{ errors }"
                          :rules="{ required: true }"
                          name="data inicial"
                        >
                          <v-text-field
                            v-model="data_inicial"
                            label="Data do Início"
                            color="#FF562B"
                            :error-messages="errors[0]"
                            outlined
                            v-mask="'##/##/####'"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col col="3" md="3" xl="3">
                        <v-text-field
                          v-model="data_final"
                          label="Data da Saída"
                          color="#FF562B"
                          outlined
                          v-mask="'##/##/####'"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <ValidationProvider
                          #default="{ errors }"
                          :rules="{ required: true }"
                          name="Situação"
                        >
                          <v-select
                            v-model="situacao"
                            :items="situacoes"
                            label="Situação"
                            :error-messages="errors[0]"                        
                            outlined
                          />
                        </ValidationProvider>
                      </v-col>
                  </v-row>
                <v-row>
                  <v-col col="12">
                      <v-textarea
                        v-model="observacao"
                        label="Observação"
                        color="#FF562B"
                        outlined
                        multiline
                      >
                      </v-textarea>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col col="12">
                    <v-checkbox v-model="experiencia" label="Experiência VamoMexe"/>
                  </v-col>
                </v-row> -->
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-row>
                  <v-col col="12" md="4" xl="4" sm="4">
                    <v-btn @click="cancelar" color="grey" width="100%" dark>Cancelar</v-btn>
                  </v-col>
                  <v-col col="12" md="4" xl="4" sm="4">
                    <v-btn
                      color="red"
                      width="100%"
                      dark
                      @click="excluir()"
                      :disabled="isDisabled"                      
                      >Excluir <v-icon class="ml-2">mdi-delete</v-icon></v-btn
                    >
                  </v-col>
                  <v-col col="12" md="4" xl="4" sm="4">
                    <v-btn color="#FF562B" width="100%" dark type="submit"
                      >Salvar <v-icon class="ml-2">mdi-content-save</v-icon></v-btn
                    >
                  </v-col>
                  <!-- <v-col col="6" md="6" xl="6" sm="6">
                    <v-btn color="#FF562B" width="100%" dark @click="rota('cadastro')">
                      Dados Pessoais<v-icon class="ml-2">mdi-arrow-left-bold-circle-outline</v-icon></v-btn
                    >
                  </v-col>
                  <v-col col="6" md="6" xl="6" sm="6">
                    <v-btn color="#FF562B" width="100%" dark @click="rota('cadastroagendaaluno')" v-if="alunos.length>0">
                      Agenda<v-icon class="ml-2">mdi-arrow-right-bold-circle-outline</v-icon></v-btn
                    >
                  </v-col> -->
                </v-row>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-card>        
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
          <v-card>                                        
              <v-data-table
                  :headers="headers"
                  :items="alunos"
                  :items-per-page="10"

                  :footer-props="{ 'items-per-page-text': 'Linhas por página' }"
                  >
                  <template v-slot:[`item.id`]="{ item }">
                      <span :class="getRowClass(item.id)+''">
                          {{ item.id }}
                      </span>
                  </template>
                  <template v-slot:[`item.is_secundario`]="{ item }">
                    <v-badge
                      :color="item.is_secundario ? 'yellow' : 'green'"
                      text-color="black"
                      :content="item.is_secundario ? 'Secundário' : 'Principal'"
                      
                    >
                      <!-- <span>{{ item.is_secundario ? 'Secundário' : 'Principal' }}</span> -->
                    </v-badge>
                  </template>
                  <template v-slot:[`item.data_inicial`]="{ item }">
                      <span>
                          {{ formatarData(item.data_inicial) }}
                      </span>
                  </template>
                  <template v-slot:[`item.produto`]="{ item }">
                      <span>
                        <v-badge
                          :color="getProdutoColor(item.produto)"
                          :content="getProduto(item.produto)"
                          
                        >
                        </v-badge>
                          <!-- {{ getProduto(item.produto) }} -->
                      </span>
                  </template>
                  <template v-slot:[`item.data_final`]="{ item }">
                      <span>
                          {{ item.data_final ? formatarData(item.data_final) : '' }}
                      </span>
                  </template>
                  <template v-slot:[`item.situacao`]="{ item }">
                      <span>
                          {{ getSituacao(item.situacao) }}
                      </span>
                  </template>
                  <!-- <template v-slot:[`item.experiencia`]="{ item }">
                      <span>
                          {{ item.experiencia == true ? 'Sim' : 'Não'  }}
                      </span>
                  </template> -->
                  <template v-slot:[`item.observacao`]="{ item }">
                    <v-tooltip top v-if="item.observacao" color="black">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            medium
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            >
                            mdi-note-text-outline
                            </v-icon>
                        </template>
                        <span>{{ item.observacao }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.editar`]="{ item }">
                      <v-icon
                      medium
                      class="mr-2"
                      @click="editarAluno(item)"
                      >
                      mdi-pencil-outline
                      </v-icon>
                  </template>
                  <template v-slot:[`item.transferir`]="{ item }">
                      <v-icon v-if="item.situacao==1"
                      medium
                      class="mr-2"
                      :disabled="item.is_secundario"
                      @click="abreTransf(item)"
                      >
                     {{ item.is_secundario ? 'mdi-block-helper' : 'mdi-account-arrow-right'}}
                      </v-icon>
                  </template>
              </v-data-table>                        
          </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog"  max-width="300px">
      <v-card>
          <v-toolbar
          color="warning"
          dark
          >Atenção</v-toolbar>
          <v-card-text>
          <!-- <div class="ma-2">
              {{ subtituloModal }}
          </div>-->
          <h3 class="mt-12" style="white-space: pre-line;">
            {{ mensagem }}
          </h3> 
          <!-- <div v-if=obs_aluno class="ma-2" style="white-space: pre-line;">
            <b>Aluno</b> {{'\n' + obs_aluno }}
          </div> 
          <div v-if=obs_agenda class="ma-2" style="white-space: pre-line;">
            <b>Agenda:</b> {{ '\n' + obs_agenda }}
          </div> 
          <div v-if=obs_contrato class="ma-2" style="white-space: pre-line;">
            <b>Contrato:</b> {{ '\n' + obs_contrato }}
          </div>  -->
          </v-card-text>
          <v-card-actions class="justify-end">
          <v-btn
              text
              @click="fechaModal()"
          >Fechar</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
    <v-dialog v-model="dialogtransf" width="500">
      <v-card>
        <v-card-title class="headline" primary-title>
            Transferência
        </v-card-title>
        <v-card-text class="pa-5">                
            <ValidationObserver ref="formtransferencia" #default="{ handleSubmit }">
                <v-form @keyup.enter="handleSubmit(transferirAluno)" @submit.prevent="handleSubmit(transferirAluno)">
                    <v-card-text>
                        <v-row>                                    
                            <v-col col="12" md="12" xl="12" sm="12">
                              <ValidationProvider
                                #default="{ errors }"
                                :rules="{ required: true }"
                                name="treinador_novo"
                              >
                                <v-select
                                  v-model="treinador_novo"
                                  :items="treinadores_ativos"
                                  label="Treinador"
                                  :error-messages="errors[0]"
                                  item-text="nome"
                                  item-value="id"
                                  outlined
                                />
                              </ValidationProvider>
                            </v-col>
                            <v-col col="6" md="12" xl="6" sm="12">
                              <ValidationProvider
                                #default="{ errors }"
                                :rules="{ required: true }"
                                name="Unidade"
                              >
                                <v-select
                                  v-model="academia_nova"
                                  :items="academias"
                                  label="Unidade"
                                  :error-messages="errors[0]"
                                  item-text="nome"
                                  item-value="id"
                                  outlined
                                />
                              </ValidationProvider>
                            </v-col>
                            <v-col col="6" md="12" xl="6" sm="12">
                              <ValidationProvider
                                #default="{ errors }"
                                :rules="{ required: true }"
                                name="data troca"
                              >
                                <v-text-field
                                  v-model="data_inicial_nova"
                                  label="Data da Troca"
                                  color="#FF562B"
                                  :error-messages="errors[0]"
                                  outlined
                                  v-mask="'##/##/####'"
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </v-col>
                                
                                <v-col col="12" md="12" xl="12" sm="12">
                              <ValidationProvider
                                #default="{ errors }"
                                :rules="{ required: true }"
                                name="motivo"
                              >
                                <v-select
                                  v-model="motivo"
                                  :items="motivos"
                                  label="Motivo transferência"
                                  :error-messages="errors[0]"
                         
                                  outlined
                                />
                            
                              </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                          <v-col col="12" md="4" xl="4" sm="4" class="pa-1">
                            <v-btn color="#FF562B" width="100%" dark type="submit" class="pa-2"
                              >Transferir<v-icon class="ml-2">mdi-content-save</v-icon></v-btn
                            >
                          </v-col>
                        </v-row>
                        </v-card-text>
                </v-form>
            </ValidationObserver>
        </v-card-text>
        <v-card-actions class="pa-5">
            <v-btn class="ml-auto" @click="fechaTransf()" outlined color="primary">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import _usuario from "@/services/usuario/usuario-servico.js";
import _treinador from "@/services/treinador/treinador-servico.js";
import _aluno from "@/services/aluno/aluno-servico.js";
import _utils from "@/utils/utils.js";
import _academia from "@/services/academia/academia-servico.js";
import _produtos from "@/models/produtos.js"
import _situacao_aluno from "@/models/situacao-aluno.js";
import AppAlerta from "@/components/AppAlerta.vue";
import AppConfirma from "@/components/AppConfirma.vue";
import _motivos from "@/models/tipo-motivo-transferencia.js"

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AppAlerta,
    AppConfirma,
  },

  data() {
    return {
      dialogs: {
        dialog: false,
        titulo: "",
      },
      id: 0,
      usuarios: [],
      treinadores: [],
      treinadores_ativos: [],
      aluno: [],
      aluno_transf: [],
      alunos: [],
      motivo: "",
      motivos: _motivos,
      academias: [],
      academia: "",
      situacoes: _situacao_aluno,
      situacao: "",
      produto: "",
      produtoTipo: "",
      experiencia: false,
      pessoa: "",      
      pessoa_aluno: null,
      is_secundario: "",
      treinador: "",            
      data_inicial: "",
      data_final: "",
      academia_nova: "",
      treinador_novo: "",
      data_inicial_nova: "",
      tab: null,
      isDisabled: true,
      pessoaDesativada: false,
      origem: "alunos",
      show: false,
      snackbarColor: '',
      snackbarText: '',  
      produtos: _produtos,
      observacao: '',
      dialog: false,
      dialogtransf: false,
      mensagem: '',
      idAlunoSelecionado: "",
      countProdutoAcademia: 0,
      mensagemProdutoNegado: "",
      isAluno: true,
      headers: [
                { text: 'Código', value: 'id' },
                { text: 'Treinador', value: 'treinador' },
                { text: 'Categoria', value: 'is_secundario' },
                { text: 'Unidade', value: 'academia' },
                { text: 'Data Início', align: 'start', value: 'data_inicial' },
                { text: 'Data de Saída', value: 'data_final' },
                { text: 'Produto', value: 'produto' },
                // { text: 'Experiência', value: 'experiencia' },                
                { text: 'Situação', value: 'situacao' },
                { text: 'Observação', value: 'observacao' },
                { text: 'Editar', value: 'editar', align: 'center', sortable: false },
                { text: 'Transferir', value: 'transferir', align: 'center', sortable: false },
            ],
    };
  },
  

  mounted() {
    _usuario.listarUsuarios().then((response) => {
      response.forEach((usuario) => {
        this.usuarios.push({
          id: usuario.id,
          nome: usuario.nome,
        });
      });    
    });

    _treinador.listartreinadores().then((response) => {
      response.forEach((treinador) => {
        this.treinadores.push({
          id: treinador.id,
          nome: treinador.nome,
        });
      });
    });

    

    _academia.listarAcademias().then((response) => {
      response.forEach((academia) => {
        this.academias.push({
          id: academia.id,
          nome: academia.nome,
        });
      });
    });
    
    if(this.$route.params.nome){
      this.buscarNovo(this.$route.params.id)
    } else {

      this.idAlunoSelecionado = this.$route.params.id
      this.buscar(this.$route.params.id)
    }
  },

  computed: {
    getAlunoSelecionado() {
      return this.idAlunoSelecionado
    },
  },

  methods: {

    
      
     getRowClass(item) {
      return item.id === this.idAlunoSelecionado ? 'aluno-selecionado' : '';
    },
    getProduto(produto) {
      return _produtos[produto].text;
    },

    getProdutoColor(produto) {
      return _produtos[produto].color;
    },

    getSituacao(situacao) {  
      return _situacao_aluno[situacao-1].text;
    },

    editarAluno(aluno)
    {
        this.id = aluno.id
        this.aluno = aluno
        this.pessoa = aluno.usuario_id
        this.pessoa_aluno = { id: aluno.usuario_id, nome: aluno.nome }
        this.treinador = aluno.treinador_id
        this.treinador_atual = aluno.treinador_id
        this.academia = aluno.academia_id
        this.produto = aluno.produto
        this.data_inicial = _utils.formatarDataTela(aluno.data_inicial)
        if (this.aluno.data_final != null)
          this.data_final = _utils.formatarDataTela(aluno.data_final)
        this.experiencia = aluno.experiencia        
        this.pessoaDesativada = true
        this.isDisabled = false
        this.observacao = aluno.observacao
        this.situacao = aluno.situacao
    },

    buscar(id) {
      _aluno.buscarAluno(id).then((response) => {
        _aluno.buscarAlunoUsuario(response.usuario_id).then((response) => {
        if (response.length == 0) {
          // this.pessoa = usuario_id
          // this.pessoa_aluno = { id: usuario_id, nome: this.$route.params.usuario.nome }  
          // this.pessoaDesativada = true
        }        
        else
        {
          this.alunos = response
          this.getTreinadoresAtivos()
          this.aluno = response[0]
          this.pessoa = this.aluno.usuario_id
          this.pessoa_aluno = { id: this.aluno.usuario_id, nome: this.aluno.nome }
          this.pessoaDesativada = true
          //this.editarAluno(response[0])
          // if (response.length > 1)
          // {
              
          // }
          // else {
            // this.id = response[0].id
            // this.aluno = response[0]
            // this.pessoa = this.aluno.usuario_id
            // this.pessoa_aluno = { id: this.aluno.usuario_id, nome: this.aluno.nome }
            // this.treinador = this.aluno.treinador_id
            // this.academia = this.aluno.academia_id
            // this.produto = this.aluno.produto
            // this.data_inicial = _utils.formatarDataTela(this.aluno.data_inicial)
            // if (this.aluno.data_final != null)
            //   this.data_final = _utils.formatarDataTela(this.aluno.data_final)
            // this.experiencia = this.aluno.experiencia
            // this.isDisabled = false
            // this.pessoaDesativada = true
          }
      });
      })
    },

    async buscarNovo(id) {
      _aluno.buscarAlunoUsuario(id).then((response) => {
        if (response.length == 0) {
          this.pessoa = id
          this.pessoa_aluno = { id: id, nome: this.$route.params.nome }  
          this.pessoaDesativada = true
          this.isAluno = false
          this.getTreinadoresAtivos()

        }        
        else
        {
          this.alunos = response
          this.getTreinadoresAtivos()
          this.aluno = response[0]
          this.pessoa = this.aluno.usuario_id
          this.pessoa_aluno = { id: this.aluno.usuario_id, nome: this.aluno.nome }
          this.pessoaDesativada = true
          //this.editarAluno(response[0])
          // if (response.length > 1)
          // {
              
          // }
          // else {
            // this.id = response[0].id
            // this.aluno = response[0]
            // this.pessoa = this.aluno.usuario_id
            // this.pessoa_aluno = { id: this.aluno.usuario_id, nome: this.aluno.nome }
            // this.treinador = this.aluno.treinador_id
            // this.academia = this.aluno.academia_id
            // this.produto = this.aluno.produto
            // this.data_inicial = _utils.formatarDataTela(this.aluno.data_inicial)
            // if (this.aluno.data_final != null)
            //   this.data_final = _utils.formatarDataTela(this.aluno.data_final)
            // this.experiencia = this.aluno.experiencia
            // this.isDisabled = false
            // this.pessoaDesativada = true
          }
      });
    },

    async getTreinadoresAtivos() {
      _treinador.listarTreinadoresAtivos().then((response) => {
        this.treinadores_ativos = response.filter(treinador => !this.alunos.some(aluno => aluno.treinador_id === treinador.id))
        .map(({ id, nome }) => ({ id, nome }));
        }); 
      
    },

    salvar() {
      if (this.situacao == 2 && this.data_final == "")
      {
        this.abreModal("Informe a data da saída!")
      }
      else if (this.situacao != 2 && this.data_final != "")
      {
        this.abreModal("Remova a data da saída!")
      }
      else
      {
          this.produtoTipo = this.aluno.produto
          this.aluno = {     
            id: this.id,     
            usuario_id: parseInt(this.pessoa_aluno.id),
            treinador_id: this.treinador,
            academia_id: this.academia,
            data_acesso: null,
            acesso: false,
            produto: this.produto,
            experiencia: this.experiencia,          
            data_inicial: _utils.formatarData(this.data_inicial),        
            data_final: this.data_final == "" ? null : _utils.formatarData(this.data_final),
            observacao: this.observacao == null ? "" : this.observacao,
            situacao: this.situacao,
            is_secundario: this.produtoTipo ? this.aluno.is_secundario ? true : false : false
          };
          
          if (this.id == 0) {
            if(this.produtoTipo === this.produto){
              this.aluno.is_secundario = true
            }
            this.verificarAlunoTreinador(this.produto) ? this.cadastrar() : this.abreModal(this.mensagemProdutoNegado)

          } else {       
              this.atualizar();
          }
      }      
    },

    verificarAlunoTreinador(produto){
      let liberar = true
      if(this.alunos.length > 0){
        this.alunos.forEach((aluno) => {
        if(aluno.produto == 0){
                  this.countProdutoAcademia ++
                }
      })
      this.alunos.forEach((aluno) => {
                if(aluno.produto == produto && aluno.produto != 0){
                    this.mensagemProdutoNegado = `Somente pode ter um treinador  no produto ${_produtos[produto].text}`
                    liberar = false
                 }
                if(aluno.produto == produto && aluno.produto == 0 && this.countProdutoAcademia >= 2){
                    this.mensagemProdutoNegado = `Somente pode ter 2 treinadores  no produto ${_produtos[produto].text}`
                    liberar = false
                 }
            })
      this.countProdutoAcademia = 0
      }
      return liberar
    },

    cadastrar() {      
      _aluno
        .cadastrarAluno(this.aluno)
        .then((response) => {
          this.snackbarColor = 'success'; // ou 'error', 'info', etc.
          this.snackbarText = 'Aluno cadastrado com sucesso!';
          this.show = true;
          this.id = this.$route.path.includes('usuario') ? response.id : this.idAlunoSelecionado
          setTimeout(() => {
              this.show = false;
            }, 3000);
              this.$router.push({ path: `/aluno/${this.id}/dados-academia` })
          
          this.limpaForm()          
        })
        .catch((error) => {          
          console.log(error);
        });
    },

    atualizar() { 
      _aluno.atualizarAluno(this.id, this.aluno).then(() => {
        this.snackbarColor = 'success'; // ou 'error', 'info', etc.
          this.snackbarText = 'Aluno atualizado com sucesso!';
          this.show = true;
          setTimeout(() => {
              this.show = false;
              this.$router.push({ path: `/aluno/${this.idAlunoSelecionado}/dados-academia` })
            }, 3000);
          this.limpaForm()          
      });
    },

    excluir() {
      this.dialogs.dialog = true;
      this.dialogs.titulo = "Excluir Aluno";
      this.dialogs.mensagem = "Deseja excluir o aluno?";
      this.dialogs.tipo = "Excluir"
    },

    getResposta(value) {
      this.resposta = value;
      if (this.resposta[0] == "Excluir" && this.resposta[1] == true) {
        _aluno.deletarAluno(this.id).then(() => {
          this.snackbarColor = 'success'; // ou 'error', 'info', etc.
          this.snackbarText = 'Aluno excluído com sucesso!';
          this.show = true;
          setTimeout(() => {
              this.show = false;
            }, 3000);
            this.limpaForm()            
        });        
      }
    },

    transferir(){
      _aluno.trasnfereAluno(this.aluno_historico).then(() => {
        this.snackbarColor = 'success'; // ou 'error', 'info', etc.
          this.snackbarText = 'Aluno transferido com sucesso!';
          this.show = true;
          setTimeout(() => {
              this.show = false;
            }, 3000);
          this.atualizar() 
          this.buscar(this.aluno.id)
          this.fechaTransf()          
          this.limpaForm()
      });
    },

    cancelar() {
      // this.rota("alunos");
      this.limpaForm()
    },    

    // rota(rota){      
    //     if (rota == 'cadastro')
    //       this.$router.push({name:rota, params: {id: this.pessoa, tipo: 'Aluno'}})    
    //     else
    //       this.$router.push({name:rota, params: {aluno: this.aluno, tipo: 'Aluno'}})    
    // },

    formatarData(data) {
        return _utils.formatarDataTela(data);
    },

    limpaFormCadastrar() {
      this.id = 0
      this.treinador = ""
      this.academia = ""
      this.produto = ""
      this.experiencia = false
      this.data_inicial = ""
      this.data_final = ""
      this.observacao = ""
      this.situacao = ""
      this.$refs.form.reset()
    },
    limpaForm() {
      this.alunos = []
      this.buscar(this.id)
      this.id = 0
      this.treinador = ""
      this.academia = ""
      this.produto = ""
      this.experiencia = false
      this.data_inicial = ""
      this.data_final = ""
      this.observacao = ""
      this.situacao = ""
      this.$refs.form.reset()
    },

    async abreModal(mensagem){
      // this.tituloModal = "Observações"
      // this.subtitulo =  nome + " | " + treinador
      // this.obs_aluno = obs_aluno
      // this.obs_agenda = obs_agenda
      // this.obs_contrato = obs_contrato
      this.mensagem = mensagem,
      this.dialog = true
    },
    fechaModal()
    {
      this.dialog = false
      this.$router.push({ path: `/aluno/${this.idAlunoSelecionado}/dados-academia` })
    },

    abreTransf(aluno_edit)
    {
        this.id = aluno_edit.id;
        this.aluno_transf = aluno_edit;
        this.dialogtransf = true;     
    },    
    fechaTransf()    
    {
      this.dialogtransf = false;    
    },    
    transferirAluno()
    {   
      this.id = this.aluno_transf.id;      
      this.aluno_historico = {          
          aluno_id: this.aluno_transf.id,
          treinador_id: this.aluno_transf.treinador_id,
          treinador_atual: this.treinador_novo,
          academia_id: this.aluno_transf.academia_id,
          produto: this.aluno_transf.produto,
          motivo: this.motivo,
          data_inicial: this.aluno_transf.data_inicial,
          data_final: this.data_inicial_nova == "" ? null : _utils.formatarData(this.data_inicial_nova),
      };  
      this.aluno = {       
            id: this.aluno_transf.id,   
            usuario_id: this.aluno.usuario_id,
            treinador_id: this.treinador_novo,
            academia_id: this.academia_nova,
            data_acesso: null,
            acesso: false,
            produto: this.aluno_transf.produto,
            experiencia: this.aluno_transf.experiencia,          
            data_inicial: _utils.formatarData(this.data_inicial_nova),
            data_final: null,              
            observacao: this.aluno_transf.observacao == null ? '' : this.aluno_transf.observacao,
            situacao: this.aluno_transf.situacao
          };      
      this.transferir();
    },
  },
};
</script>
<style scoped>
.aluno-selecionado {
  background-color: #ffcccc !important; /* Vermelho claro */
}

</style>