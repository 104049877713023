import api from "../api/icaro"

const icaro = {
  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listartreinadores() {
    return new Promise((resolve, reject) => {
      api
        .get(`/treinadores/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },


  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listarTreinadoresAtivos() {
    return new Promise((resolve, reject) => {
      api
        .get(`/treinadores/ativos/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} treinador
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  cadastrartreinador(treinador) {
    return new Promise((resolve, reject) => {
      api
        .post(`/treinadores/`, treinador)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  atualizartreinador(id, treinador) {
    return new Promise((resolve, reject) => {
      api
        .put(`/treinadores/${id}`, treinador)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  deletartreinador(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/treinadores/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} idtreinador
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  buscartreinador(idtreinador) {
    return new Promise((resolve, reject) => {
      api
        .get(`/treinadores/${idtreinador}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  listarAgenda(idTreinador) {
    return new Promise((resolve, reject) => {
      api
        .get(`/treinadores/agenda/listar/${idTreinador}/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} idtreinador
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  buscarAgendaTreinador(idtreinador) {
    return new Promise((resolve, reject) => {
      api
        .get(`/treinadores/agenda/${idtreinador}`)
        .then((response) => {
          console.log(response.data)
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },
  agendaCompleta(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/treinadores/agenda/completa/treinador/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },
  

  /**
   * @param {Object} idtreinador
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  buscarAgendaTreinadorDia(idtreinador, dia) {
    return new Promise((resolve, reject) => {
      api
        .get(`/treinadores/agenda/id/${idtreinador}/dia/${dia}`)
        .then((response) => {
          console.log(response.data)
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  listarAgendaGeral() {
    return new Promise((resolve, reject) => {
      api
        .get(`/treinadores/agenda/geral/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
     *
     * @returns {Promise} response.data ou erro
     */
  buscarTotais() {
    return new Promise((resolve, reject) => {
      api
        .get(`/treinadores/ranking-totais/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  buscarRanking() {
    return new Promise((resolve, reject) => {
      api
        .get(`/treinadores/ranking/`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   *
   * @returns {Promise} response.data ou erro
   */
  buscarRankingAcademia(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/treinadores/ranking-academia/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  /**
   * @param {Object} treinador
   * @returns {Promise} response.data ou erro
   * @memberof icaro
   * */
  cadastrarAgenda(agenda) {
    return new Promise((resolve, reject) => {
      api
        .post(`/treinadores/agenda/`, agenda)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },
// VAMO MEXE --------------------------------
  buscarExperiencia(idtreinador, diaSemana, horario) {
    return new Promise((resolve, reject) => {
      api
        .get(`/treinadores/agenda/experiencia/vamo-mexe/${idtreinador}/semana/${diaSemana}/horario/${horario}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  cadastrarVamoMexe(vamoMexe) {
    return new Promise((resolve, reject) => {
      api
        .post(`/treinadores/agenda/vamo-mexe/cadastrar`, vamoMexe)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },
  atualizarStatusVamoMexe(id, value, column) {
    return new Promise((resolve, reject) => {
      api
        .put(`/treinadores/agenda/experiencia/vamo-mexe/${id}/${column}`, value)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },

  deletarAgenda(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/treinadores/agenda/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((erro) => {
          reject(erro)
        })
    })
  },
}

export default icaro