<template>
  <div>
    <div class="tabs-container">
      <router-link 
        class="tab" 
        :class="{ active: $route.path.includes('dados-pessoais') }" 
        :to="`/usuario/${$route.params.id}/${$route.params.tipo}/${$route.params.nome}/dados-pessoais`"
      >
        Dados Pessoais
      </router-link>

      <router-link
      v-if="$route.params.tipo === 'aluno'"
        class="tab" 
        :class="{ active: $route.path.includes('dados-academia') }" 
        :to="`/usuario/${$route.params.id}/${$route.params.tipo}/${$route.params.nome}/dados-academia`"
      >
        Dados Academia
      </router-link>

      <router-link
        v-if="$route.params.tipo === 'treinador'" 
        class="tab" 
        :class="{ active: $route.path.includes('dados-treinador') }" 
        :to="`/usuario/${$route.params.id}/${$route.params.tipo}/${$route.params.nome}/dados-treinador`"
      >
        Dados Treinador
      </router-link>
    </div>

    <div class="tab-content">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { ref, watch } from 'vue'

const route = useRoute()
const abaAtual = ref(route.path)
watch(route, (novaRota) => {
  abaAtual.value = novaRota.path
})
</script>
<style scoped>
/* Insira o CSS fornecido aqui */
.tabs-container {
  display: flex;
  border-bottom: 2px solid #ddd;
  margin-bottom: 10px;
}


.tab {
  text-decoration: none; /* Remove sublinhado */
  display: inline-block; /* Garante que a borda inferior funcione corretamente */
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #555;
  transition: color 0.3s, border-bottom 0.3s;
  border-bottom: 2px solid transparent;
}

.tab:hover {
  color: #ff562b;
}

.tab.active {
  color: #ff562b;
  border-bottom: 2px solid #ff562b;
}

.tab-content {
  padding: 15px;
  background: #f9f9f9;
  border-radius: 5px;
}

</style>