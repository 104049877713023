// const tipoContrato = [
//     { text: 'Individual - Mensal', value: 0 },
//     { text: 'Individual - Bimestral', value: 1 },
//     { text: 'Individual - Trimestral', value: 2 },
//     { text: 'Individual - Quadrimestral', value: 3 },
//     { text: 'Individual - Octamensal', value: 4 },
//     { text: 'Individual - Semestral', value: 5 },
//     { text: 'Individual - Anual', value: 6 },
//     { text: 'Coletivo - Mensal', value: 7 },
//     { text: 'Coletivo - Bimestral', value: 8 },
//     { text: 'Coletivo - Trimestral', value: 9 },
//     { text: 'Coletivo - Quadrimestral', value: 10 },
//     { text: 'Coletivo - Octamensal', value: 11 },
//     { text: 'Coletivo - Semestral', value: 12 },
//     { text: 'Coletivo - Anual', value: 13 },
// ]

const tipoContrato = [
    { text: "Mensal Individual", value: 0, meses: 1 },
        { text: "Mensal Coletivo", value: 7, meses: 1 },
        { text: "Trimestral", value: 2, meses: 3 },
        { text: "Quadrimestral", value: 3, meses: 4 },
        { text: "Quinquemestral", value: 8, meses: 5 },
        { text: "Semestral", value: 5, meses: 6 },
        { text: "Octamensal", value: 4, meses: 8 },
        { text: "Anual", value: 6, meses: 12 },
        { text: "Outro período", value: 13, meses: 0 }
]

export default tipoContrato;