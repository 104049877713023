<template>
  <ValidationProvider v-slot="{ errors, validate }" rules="required" name="Data">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="formattedDate"
          :label="label"
          prepend-inner-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          :error-messages="errors"
          @blur="validate()"
          :outlined="outlinedOption" 

        />
      </template>
      <v-date-picker
        v-model="internalDate"
        locale="pt-BR"
        :allowed-dates="permitirDias"
        @input="updateDate(validate)"
        @update:modelValue="updateDate(validate)" 
      />
    </v-menu>
  </ValidationProvider>
</template>

<script>
import _utils from "../utils/utils.js";

export default {
  props: {
    value: String,
    label: {
      type: String,
      default: "Selecione a Data",
    },
    allowedWeekDay: {
      type: Number,
      default: null,
    },
    outlinedOption: {
      type: Boolean,
      default: true,
    },
    extraItem: {
      type: Number,  
      default: null
    },
  },
  
  data() {
    return {
      menu: false,
      internalDate: this.value || "",
      isOutlined: false
    };
  },
  computed: {
    formattedDate() {
      return this.internalDate ? _utils.formatarDataTela(this.internalDate) : "";
    },
    setExtraItem() {
      console.log(this.extraItem);
      return this.extraItem;
    },
  },

  watch: {
    value(newVal) {
      this.internalDate = newVal || ""; // Atualiza a internalDate quando um novo formulário é carregado
    }
  },

  methods: {
    updateDate(validate) {
      if (!this.internalDate) return "";
      const dataFormatada = _utils.formatarDataBanco(this.internalDate);
      this.$emit("input", dataFormatada); // Emite para o pai
      this.$emit("change", {extra: this.setExtraItem, data: dataFormatada}); // Emite evento personalizado
      validate(); // Valida novamente ao selecionar uma data
      this.menu = false;
    },

    permitirDias(date) {
      if (this.allowedWeekDay === null) return true; // Se não for passado, permite todos os dias

      const diaSemana = new Date(date).getDay(); // Obtém o dia da semana

      return diaSemana === this.allowedWeekDay; // Apenas o dia permitido
    },

    setOutlinedOption() {
      return this.outlinedOption;
    },

  },
};
</script>
