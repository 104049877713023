<template>
  <v-container fluid>
    <!-- <v-toolbar color="orange" dense>
      <v-toolbar-title>Cadastro Usuário | 1 - Dados Pessoais</v-toolbar-title>
    </v-toolbar> -->
    <v-divider></v-divider>
    <AppConfirma :dialogs="dialogs" @resposta="getResposta"></AppConfirma>    
    <AppAlerta :color="snackbarColor" :text="snackbarText" v-if="show"/>

    <v-spacer class="mt-4"></v-spacer>
    <v-row align="center" justify="center">
      <v-col col="12" md="12" xl="12" xs="12" sm="12">
        <v-card>
          <ValidationObserver ref="form" #default="{ handleSubmit }">
            <v-form
              @keyup.enter="handleSubmit(salvar)"
              @submit.prevent="handleSubmit(salvar)"
              @reset.prevent="reset"
            >
            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="2">
                    <v-text-field
                      v-model="cpf"
                      label="CPF"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'CPF é obrigatório']"
                      outlined
                      required
                      v-mask="'###.###.###-##'"
                      :disabled="id !=0 ? true : false"
                    >
                    </v-text-field>
                  </v-col>   
                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="tipo"
                      :items="['Aluno', 'Treinador', 'Sem relacionamento']"
                      label="Relacionamento"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'Relacionamento é obrigatório']"
                      outlined
                      required
                      :disabled="(id !=0 && tipo != 'Sem relacionamento'|| id == 0 && tipo != 'Sem relacionamento' ) ? true : false"
                    />
                  </v-col>
                  <!-- Novo Campo "Since" -->
                      <v-col cols="12" md="4" xl="4">
                      <v-text-field
                        v-model="since"
                        label="Since"
                        color="#FF562B"
                        outlined
                        v-mask="'##/##/####'"
                        :disabled="isSinceLocked"
                      >
                        <!-- Ícone no canto direito do campo -->
                        <template v-slot:append>
                          <v-icon  v-if="!isEditing && since" @click="handleUnlockClick">
                            {{ isSinceLocked ? 'mdi-lock' : 'mdi-lock-open' }}
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" xl="2">
                              <v-switch
                                v-model="mudarStatus"
                                :label="switchLabel"
                                color="green"   
                                base-color="red" 
                                @change="toggleModal"
                                class=""
                              ></v-switch>
                              
                       

                    </v-col>

                    <!-- Diálogo de Confirmação -->
                    <v-dialog v-model="dialogConfirm" max-width="400">
                      <v-card>
                        <v-card-title class="headline">Confirmar alteração</v-card-title>
                        <v-card-text>Tem certeza que deseja alterar esta data? Somente altere se o aluno estava inativo e retornou ou se foi digitada errado no cadastro</v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn color="red darken-1" text @click="cancelEdit">Cancelar</v-btn>
                          <v-btn color="green darken-1" text @click="confirmChange">Sim</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>                  
                  <v-col cols="12" sm="9">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="nome"
                    >
                      <v-text-field
                        v-model="nome"
                        label="Nome"
                        color="orange"
                        :error-messages="errors[0]"
                        outlined
                        :disabled="desabilitado"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <ValidationProvider
                      #default="{ errors }"
                      :rules="{ required: true }"
                      name="nascimento"
                      v-mask="'##/##/####'"
                    >
                      <v-text-field
                        v-model="nascimento"
                        label="Data Nascimento"
                        color="#FF562B"
                        :error-messages="errors[0]"
                        outlined
                        :disabled="desabilitado"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>                 
                </v-row>
                <v-row> 
                  
                  <v-col cols="12" sm="3">
                    <v-select
                      v-model="sexo"
                      :items="['Masculino', 'Feminino']"
                      label="Sexo"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'Sexo é obrigatório']"
                      outlined
                      required
                      :disabled="desabilitado"
                    />
                  </v-col>                  
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="rg"
                      label="RG"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'RG é obrigatório']"
                      outlined
                      required
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <ValidationProvider
                      rules="required|email"
                      mode="passive"
                      name="Email"
                    >
                      <v-text-field
                        v-model="email"
                        label="E-mail"
                        color="#FF562B"
                        :rules="[(v) => !!v || 'E-mail é obrigatório']"
                        outlined
                        :disabled="desabilitado"
                        @blur="emailtrim()"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" md="3" xl="3">
                    <v-text-field
                      v-model="telefone"
                      label="Telefone"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'Telefone é obrigatório']"
                      outlined
                      required
                      v-mask="'(##)# ####-####'"
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-dialog v-model="dialogErro" max-width="400">
                    <v-card>
                      <v-card-title class="headline">Erro ao buscar CEP</v-card-title>
                      <v-card-text>O CEP informado não foi encontrado. Verifique e tente novamente.</v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" @click="dialogErro = false">Fechar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-col cols="12" md="2" xl="2">
                    <v-text-field
                      v-model="cep"
                      label="Cep"
                      color="#FF562B" 
                      :rules="[(v) => !!v || 'Cep é obrigatório']"
                      outlined
                      v-mask="'#####-###'"

                      required
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="10" xl="10">
                    <v-text-field
                      v-model="logradouro"
                      label="Logradouro"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'Logradouro é obrigatório']"
                      outlined
                      required
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" xl="2">
                    <v-text-field
                      v-model="numero"
                      label="Número"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'Número é obrigatório']"
                      outlined
                      required
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" xl="2">
                    <v-text-field
                      v-model="complemento"
                      label="Complemento"
                      color="#FF562B"
                      outlined
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="8" xl="8">
                    <v-text-field
                      v-model="bairro"
                      label="Bairro"
                      color="#FF562B"
                      :rules="[(v) => !!v || 'Bairro é obrigatório']"
                      outlined
                      required
                      :disabled="desabilitado"
                    >
                    </v-text-field>
                  </v-col>
                  
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-row>
                  <v-col col="12" sm="4" md="4" xl="4">
                    <v-btn color="grey" width="100%" dark @click="cancelar()">Cancelar</v-btn>
                  </v-col>
                  <v-col col="12" sm="4" md="4" xl="4">
                    <v-btn
                      color="red"
                      width="100%"
                      dark
                      @click="excluir()"
                      :disabled="isDisabled"
                      >Excluir <v-icon class="ml-2">mdi-delete</v-icon></v-btn
                    >
                  </v-col>
                  <v-col col="12" sm="4" md="4" xl="4">
                    <v-btn color="#FF562B" width="100%" dark type="submit"
                      >Salvar<v-icon class="ml-2">mdi-content-save</v-icon></v-btn
                    >
                  </v-col>                                    
                    <!-- <v-col col="12" sm="12" md="12" xl="12">
                    <v-btn color="#FF562B" width="100%" dark @click="avancar()" v-if="id>0">
                      Dados do Aluno<v-icon class="ml-2">mdi-arrow-right-bold-circle-outline</v-icon></v-btn
                    >
                  </v-col> -->
                </v-row>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal (Dialog) -->
                              <v-dialog v-model="exibirInativaUsuario" max-width="400">
                                <v-card>
                                  <v-card-title class="headline">{{textoStatus}} Usuário</v-card-title>
                                  <ValidationObserver ref="alterarsatus" #default="{ handleSubmit }">
                                    <v-form @keyup.enter="handleSubmit(alterarStatus)" @submit.prevent="handleSubmit(alterarStatus)">
                                    <v-card-text>
                                      <ValidationProvider
                                          #default="{ errors }"
                                          :rules="{ required: true }"
                                          name="data entrada"                            
                                        >
                                      <v-text-field
                                          
                                          v-model="since" 
                                          label="Data de Entrada" 
                                          color="#FF562B"
                                          outlined
                                          :error-messages="errors[0]"
                                          v-mask="'##/##/####'"
                                          :disabled="campoSaida"

                                        >
                                      </v-text-field>
                                      </ValidationProvider>

                                      <ValidationProvider
                                          #default="{ errors }"
                                          :rules="{ required: campoSaida ? true : false }"
                                          name="data saida"
                                        >
                                      <v-text-field
                                          
                                          v-model="saida" 
                                          label="Data de Saída" 
                                          color="#FF562B"
                                          outlined
                                          :error-messages="errors[0]"
                                          v-mask="'##/##/####'"
                                          :disabled="!campoSaida"
                                        >
                                      </v-text-field>
                                      </ValidationProvider>

                                      <ValidationProvider
                                          #default="{ errors }"
                                          :rules="{ required: campoSaida ? true : false }"
                                          name="motivo"
                                        >
                                        <v-select
                                          v-model="motivo_saida"
                                          :items="motivos"
                                          label="Motivo"
                                          color="#FF562B"
                                          :error-messages="errors[0]"
                                          outlined
                                          :disabled="!campoSaida"
                                        />
                                      </ValidationProvider>
                                    </v-card-text>
                                    <v-card-actions>
                                          <v-btn color="#FF562B" width="" dark type="submit" class="pa-2"  
                                            >{{textoStatus}}<v-icon class="">mdi-content-save</v-icon>
                                          </v-btn>
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" outlined @click="cancelStatus">Fechar</v-btn>
                                    </v-card-actions>
                                    </v-form>
                                  </ValidationObserver>
                                </v-card>
                              </v-dialog>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import _usuario from "@/services/usuario/usuario-servico.js";
import _utils from "@/utils/utils.js";
import AppAlerta from "@/components/AppAlerta.vue";
import AppConfirma from "@/components/AppConfirma.vue";
import motivos from "@/models/tipo-motivo-saida.js"

// Add the required rule
extend("required", required);

// Add the email rule
extend("email", email);

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AppConfirma,
    AppAlerta,
  },
  data() {
    return {
      dialogs: {
        dialog: false,
      },
      since: '',
      saida: '',
      textoStatus: 'Inativo',
      mudarStatus: true,
      exibirInativaUsuario: false,
      estadoAnterior: false,
      campoSaida: false,
      motivo_saida: 0,
      motivos: motivos,
      isEditing: true, // Começa desbloqueado para novo cadastro
      isSinceLocked: false,
      dialogConfirm: false,
      confirmEdit: false,
      canEditSince: true,
      resposta: "",
      id: 0,
      nome: "",
      login: "",
      senha: "$2b$12$KPffG/xe/Gi1VW.E2e.1teSXp1jW8MJgDoBXzcbI577QIs4wY1jWS",
      email: "",
      telefone: "",
      nascimento: "",
      cpf: "",
      rg: "",
      sexo: "",
      endereco: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      // instagram: "",
      foto: "",
      usuario: [],
      isDisabled: true,
      usuarioTipoEnum: {
        aluno: "Aluno",
        treinador: "Treinador",
      },
      show: false,
      snackbarColor: '',
      snackbarText: '',  
      tipo: '',
      desabilitado: true,
      usuarioTipo: '',
      dialogErro: false,
      paramNome: false,
    };
  },

  computed: {
    switchLabel() {
      return this.mudarStatus ? "Ativo" : "Inativo";
    },
    _telefone() {
      return this.telefone.replace(/\D/g, "");
    },
    _cpf() {
      return this.cpf.replace(/\D/g, "");
    },
  },

  mounted() {
    if (this.$route.params.id != null) {
      this.tipo = this.$route.params.tipo ? this.usuarioTipoEnum[this.$route.params.tipo.toLowerCase()] : ''
      this.buscar(this.$route.params.id);
      if(!this.tipo) {this.tipo = 'Sem relacionamento'; this.paramNome = true}

    }
    else if (this.$route.params.tipo != 'sr') {
      this.tipo = this.$route.params.tipo;
    }
    else{
      this.tipo = 'Sem relacionamento';
    }
  },

  watch: {
          since(newValue) {
          if (newValue === "") {
            this.isEditing = true; // Se limpar o campo, mantém editável
            this.isSinceLocked = false;
          }
        },
        mudarStatus(novoValor) {
          if (novoValor !== this.estadoAnterior) {
            this.estadoAnterior = !novoValor;
            this.textoStatus = this.mudarStatus ? 'Reativar' : 'Inativar'
            this.campoSaida = this.mudarStatus ?  false : true
            this.toggleModal();
          }
        },

        cpf: {
            // immediate: true,
            deep: true,
            async handler (alteracoes) {
              if (this.id == 0)
              {
                const valorSemPontos = alteracoes.replace(/\D/g, "") // Remove os pontos
                if (valorSemPontos.length === 11) {
                    // Verifica se o comprimento é igual a 11
                    if (this.validaCPF(valorSemPontos)) {                        
                    //if (this.proximo) {                        
                        //await this.verificarUsuario(valorSemPontos)
                        await this.buscarUsuario(valorSemPontos)
                    //}
                    } else {
                        //this.cpf.rules = "X"                    
                    // this.mostrarMensagem({
                    //     tipo: "error",
                    //     descricao: "CPF inválido.",
                    // })
                        alert("CPF inválido");
                    }
                }
                this.desabilitado = true;
              }
              else 
              {
                  this.desabilitado = false;
              }
            },
        },
        cep: async function (novoCep) {
        const cepLimpo = novoCep.replace(/\D/g, ""); // Remove caracteres não numéricos
          if (cepLimpo.length === 8) {
            await this.buscarEndereco(cepLimpo);
          }
        }
  },

  methods: {
    handleUnlockClick () {
      if (this.since === "") return; // Se o campo estiver vazio, não faz nada

      if (this.isSinceLocked) {
        this.dialogConfirm = true; // Pede confirmação antes de liberar
      } else {
        this.isEditing = false; // Se já está editável, bloqueia de novo ao clicar
        this.isSinceLocked = true;
      }
    },
    toggleModal() {
      this.exibirInativaUsuario = true
    },
    confirmChange() {
      this.isEditing = true; // Agora o campo fica liberado!
      this.isSinceLocked = false;
      this.dialogConfirm = false;
    },
    cancelEdit() {
      this.dialogConfirm = false;
    },
    cancelStatus() {
        this.mudarStatus = this.estadoAnterior;
      this.exibirInativaUsuario = false;
    },

    async buscarEndereco(cep) {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      if (!data.erro) {
        this.logradouro = data.logradouro;
        this.bairro = data.bairro;
        this.cidade = data.localidade;
        this.uf = data.uf;
      } else {
        this.logradouro = '';
        this.bairro = '';
        this.cidade = '';
        this.uf = '';
        this.dialogErro = true;
      }
    } catch (error) {
      console.error("Erro ao buscar o CEP:", error);
    }
  },
    emailtrim()
    {
      this.email = this.email.trim()
    },

    validarJson(jsonString) {
    try {
      JSON.parse(jsonString);
      return true;
      } catch (error) {
        return false;
      }
    },

    async buscar(id) {
        if (this.$route.path.includes('treinador')) {
          this.usuarioTipo = 'treinadores';
          this.tipo = 'Treinador';
        }
        if (this.$route.path.includes('aluno')) {
          this.usuarioTipo = 'alunos';
          this.tipo = 'Aluno';
        } 
        if (this.$route.path.includes('usuario')) {
          this.usuarioTipo = 'usuarios';
        }
        await _usuario.buscarUsuarioTipo(this.usuarioTipo, id).then((response) => {
          const usuarioId = this.usuarioTipo === 'usuarios' ? response.id : response.usuario_id;
            _usuario.buscarUsuario(usuarioId).then((response) => {
              this.usuario = response;
              this.id = this.usuario.id;

              this.$router.push({params: {usuario: this.usuario}})
              this.nome = this.usuario.nome;
              if(this.usuario.since != null) {
                this.since = _utils.formatarDataTela(this.usuario.since); 
                  this.isEditing =  false 
                  this.isSinceLocked =  true
                }

              if(this.usuario.saida != null) {
                this.saida = _utils.formatarDataTela(this.usuario.saida);
                this.motivo_saida =this.usuario.motivo_saida
                this.mudarStatus = false 
              } else {
                this.estadoAnterior = true
              }

              if (this.usuario.nascimento != null)
                this.nascimento = _utils.formatarDataTela(this.usuario.nascimento);

              if (this.usuario.sexo == "M") {
                this.sexo = "Masculino";
              } else {
                this.sexo = "Feminino";
              }

              if (this.usuario.cpf != null) this.cpf = this.usuario.cpf;
              this.rg = this.usuario.rg;

              this.email = this.usuario.email;
              if (this.usuario.telefone != null)
                this.telefone = this.usuario.telefone;
              if (this.usuario.endereco != null)
              {          
                if (this.validarJson(this.usuario.endereco))
                {
                  this.endereco = JSON.parse(this.usuario.endereco);
                  this.cep = this.endereco.cep
                  this.logradouro = this.endereco.logradouro
                  this.numero = this.endereco.numero
                  this.complemento = this.endereco.complemento
                  this.bairro = this.endereco.bairro 
                }
                else
                {              
                  this.logradouro = this.usuario.endereco
                }
              }
              // this.instagram = this.usuario.instagram;
              if (this.usuario.relacionamento == null) this.isDisabled = false;
              // this.tipo = this.usuario.relacionamento;

              // console.log(this.endereco)
            }).catch(error => console.error("Erro na requisição:", error));
          }).catch(error => console.error("Erro na requisição:", error));

      
    },

    buscarUsuario(cpf) {
            _usuario.buscarUsuarioCpf(cpf).then((response) => {                
                // this.usuario = response;
                // //console.log(this.usuario);
                // this.rg = this.usuario.rg;
                // this.nome = this.usuario.nome;
                // if (this.usuario.nascimento != null)
                //     this.nascimento = _utils.formatarDataTela(this.usuario.nascimento);
                // if (this.usuario.sexo == "M") {
                //     this.sexo = "Masculino";
                // } else if (this.usuario.sexo == "F") {
                //     this.sexo = "Feminino";
                // }                
                // this.email = this.usuario.email;
                // if (this.usuario.telefone != null)
                //     this.telefone = this.usuario.telefone;
                // if (this.usuario.endereco != null)
                //     this.endereco = this.usuario.endereco;
                // // this.instagram = this.usuario.instagram;
                // // if (this.usuario.relacionamento == null) this.isDisabled = false;                
                if (response)
                {
                  this.desabilitado = true;
                  this.snackbarColor = 'success'; // ou 'error', 'info', etc.
                  this.snackbarText = 'Usuário já cadastrado!';
                  this.show = true;  
                  setTimeout(() => {
                    this.show = false;          
                    switch (response.relacionamento) {
                      case 'Treinador': this.$router.push({ path: `/treinador/${response.relacionamento_id}/dados-pessoais` })
                        break;

                      case 'Aluno': this.$router.push({ path: `/aluno/${response.relacionamento_id}/dados-pessoais` })
                        break;
                    
                      default:this.$router.push({ path: `/usuario/${response.id}/sr/${response.nome}/dados-pessoais` })  
                        break;
                    }   
                                      
                    window.location.reload()
                  }, 3000);
                }
                else 
                  this.desabilitado = false;
            }).catch(() => 
              {
                this.desabilitado = false;
              }
            );
        },

    alterarStatus() {
      this.exibirInativaUsuario = false
      this.salvar()
    },

    salvar() {
      this.endereco = {
        cep: this.cep,
        logradouro: this.logradouro,
        numero: this.numero,
        complemento: this.complemento,
        bairro: this.bairro
      };

      this.endereco = JSON.stringify(this.endereco);

      this.usuario = {
        nome: this.nome,
        login: this._cpf,
        senha: this.senha,
        email: this.email.trim(),
        telefone: this._telefone,
        nascimento: _utils.formatarData(this.nascimento),
        since: _utils.formatarData(this.since),
        saida: this.campoSaida ? _utils.formatarData(this.saida) : null ,
        cpf: this._cpf,
        rg: this.rg,
        sexo: this.sexo.substring(0, 1),
        endereco: this.endereco,
        instagram: "",
        foto: this.foto,
        objetivo: '',
        motivo_saida: this.motivo_saida ?? 0,
        precadastro: true,
        nivel_acesso: '',
        sistema: 1,
      };      
      if (this.id == 0) {
        this.salvarUsuario();
      } else if(this.paramNome) {
        this.atualizar();
      } else {        
        this.atualizar();
      }
    },

    atualizar() {      
      _usuario.atualizarUsuario(this.id, this.usuario).then(() => {        
        this.snackbarColor = 'success'; // ou 'error', 'info', etc.
        this.snackbarText = 'Usuário atualizado com sucesso!';   
        this.show = true;
        setTimeout(() => {
            this.show = false;
            if(this.paramNome){
              if(this.tipo === 'Aluno') this.$router.push({ path: `/usuario/${this.id}/aluno/${this.usuario.nome}/dados-academia` })
              if(this.tipo === 'Treinador') this.$router.push({ path: `/usuario/${this.id}/treinador/${this.usuario.nome}/dados-treinador` })
            }
          }, 3000);
      });
    },
    salvarUsuario() {
      _usuario.cadastrarUsuario(this.usuario).then((response) => {        
        this.id = response.id
        this.snackbarColor = 'success'; // ou 'error', 'info', etc.
        this.snackbarText = 'Usuário cadastrado com sucesso!';
        this.show = true;
        setTimeout(() => {
          this.show = false;
          if(this.tipo === 'Aluno') this.$router.push({ path: `/usuario/${response.id}/aluno/${this.usuario.nome}/dados-academia` })
          if(this.tipo === 'Treinador') this.$router.push({ path: `/usuario/${response.id}/treinador/${this.usuario.nome}/dados-treinador` })
        }, 3000);
      });      
    },
    excluir() {
      this.dialogs.dialog = true;
      this.dialogs.titulo = "Excluir Aluno";
      this.dialogs.mensagem =
        "Deseja excluir o usuário " + this.nome.toUpperCase() + "?";
    },
    getResposta(value) {
      this.resposta = value;

      if (this.resposta == true) {
        _usuario.deletarUsuario(this.id).then(() => {
          this.snackbarColor = 'success';
          this.snackbarText = "Usuário excluído com sucesso!";
          this.show = true;
          setTimeout(() => {
          this.show = false;
        }, 3000);
        });
      }
    },
    cancelar() {
      if (this.tipo == 'Aluno') this.$router.replace({ name: "alunos" });
      if (this.tipo == 'Treinador') this.$router.replace({ name: "treinadores" });
    },
    reset() {
      requestAnimationFrame(() => {
        (this.nome = ""), this.$refs.observer.reset();
      });
    },

    limpaForm() {
      (this.nome = null),
        (this.login = ""),
        (this.senha = ""),
        (this.email = ""),
        (this.telefone = ""),
        (this.nascimento = ""),
        (this.cpf = ""),
        (this.rg = ""),
        (this.sexo = ""),
        (this.endereco = ""),
        // (this.instagram = ""),
        (this.foto = "");
    },

    avancar(){
      if (this.tipo == 'Aluno')
        this.$router.push({name:'cadastroaluno', params: {usuario: this.usuario}})
      else if (this.tipo == 'Treinador')
        this.$router.push({name:'cadastrotreinador', params: {id: this.id}})
      else
        this.$router.push({ name: "alunos"});
    },

    verificarCPF (campo) {
            const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
            return cpfRegex.test(campo)
        },

        validaCPF (cpf) {
            var Soma = 0
            var Resto

            var strCPF = String(cpf).replace(/[^\d]/g, "")

            if (strCPF.length !== 11) { return false }

            if ([
                "00000000000",
                "11111111111",
                "22222222222",
                "33333333333",
                "44444444444",
                "55555555555",
                "66666666666",
                "77777777777",
                "88888888888",
                "99999999999",
            ].indexOf(strCPF) !== -1) { return false }

            for (let i = 1; i <= 9; i++) { Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i) }

            Resto = (Soma * 10) % 11

            if ((Resto === 10) || (Resto === 11)) { Resto = 0 }

            if (Resto !== parseInt(strCPF.substring(9, 10))) { return false }

            Soma = 0

            for (let i = 1; i <= 10; i++) { Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i) }

            Resto = (Soma * 10) % 11

            if ((Resto === 10) || (Resto === 11)) { Resto = 0 }

            if (Resto !== parseInt(strCPF.substring(10, 11))) { return false }

            return true
        },
  },
};
</script>