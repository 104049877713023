<template>
  <span>
    <v-badge
      :color="getColor()"
      :content="getText()"
    >
    </v-badge>
  </span>
</template>

<script>
import produtos from "./../models/produtos";

export default {
  props: {
    value: Number,
  },
  
  data() {
    return {
      valueProduto: this.value,
    };
  },
  computed: {
    
  },

  watch: {
    value(newVal) {
      this.valueProduto = newVal || ""; // Atualiza a internalDate quando um novo formulário é carregado
    }
  },

  methods: {
    getColor() {
      return produtos[this.valueProduto].color;
    },
    getText() {
      return produtos[this.valueProduto].text;
    },

  },
};
</script>
