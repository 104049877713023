<template>
  <v-container>
    <!-- menu date -->
    <v-row>
      <v-col cols="12">
        <h1 class="font-weight-medium">Estatísticas Gerais</h1>
        <!-- <h4 class="ml-3 grey--text">Período de 01/03/2024 à 07/03/2024</h4>         -->
      </v-col>
      <v-col cols="12" md="4">
        <v-menu
          v-model="menuDatePickerI"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <ValidationProvider #default="{ errors }" name="Data Inicial">
              <v-text-field
                v-model="data_inicial"
                label="Data Inicial"
                prepend-inner-icon="mdi-calendar"
                :error-messages="errors[0]"
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </ValidationProvider>
          </template>
          <v-date-picker
            v-model="dataInicial"
            :active-picker.sync="ativarDatePicker"
            min="1900-01-01"
            locale="pt-BR"
          />
        </v-menu>
      </v-col>
      <v-col cols="12" md="4">
        <v-menu
          v-model="menuDatePickerF"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <ValidationProvider #default="{ errors }" name="Data final">
              <v-text-field
                v-model="data_final"
                label="Data final"
                prepend-inner-icon="mdi-calendar"
                :error-messages="errors[0]"
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </ValidationProvider>
          </template>
          <v-date-picker
            v-model="dataFinal"
            :active-picker.sync="ativarDatePicker"
            min="1900-01-01"
            locale="pt-BR"
          />
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <v-checkbox
          v-model="situacao"
          label="Inativos"
          class="mt-2"
          hide-details
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="primary" height="55" width="100%" @click="listarEstatisticas()"
          ><v-icon>mdi-magnify</v-icon>Pesquisar</v-btn
        >
      </v-col>
    </v-row>
    <!-- Graficos -->
    <v-row v-if="listaDados.length > 0">
      <v-col cols="12">
        <v-card color="#FBFBFB" elevation="0">
          <v-card-title class="text-h4 font-weight-medium">
            <v-icon class="mr-2 text-h4">mdi-chart-bar</v-icon> DASHBOARD GERAL
          </v-card-title>
         <v-row>
      <!-- Card TOTAL -->
      <v-col cols="12" md="4">
        <v-card class="pa-4 ml-2">
          <v-card-title class="d-flex justify-center">Total</v-card-title>
          <v-card-text class="d-flex flex-column align-center">
            <!-- <v-icon class="mr-2 text-h4">mdi-account-group</v-icon>
            <div class="text-h3 font-weight-bold pa-4">{{ totalAlunos }}</div> -->
            <!-- <GraficoRosca :chart-data="chartDataTotal" /> -->
            <GraficoRosca :chart-data="chartDataTotal" />
            <v-list>
              <v-list-item v-for="(item, index) in chartDataTotal.labels" :key="index">
                <v-list-item-icon>
                  <v-icon :style="{ color: chartDataTotal.datasets[0].backgroundColor[index] }">mdi-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item }} : {{ chartDataTotal.datasets[0].data[index] }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
          <template>
            <div>
              <!-- Chamando o componente CardInfo com diferentes valores -->
              <CardInfo :value="totalAlunos" icon="mdi-account" text="Total de alunos: ativos" :corAvatar="coresAcademias.academais.corAvatar"/>
              <CardInfo :value="ticketMedio" icon="mdi-cash" text="Ticket Médio Total: ativos" :corAvatar="coresAcademias.academais.corAvatar"/>
              <CardInfo :value="ltvTotal" icon="mdi-history" text="Ltv Total: ativos" :corAvatar="coresAcademias.academais.corAvatar"/>
              <CardInfo :value="contratoTotal" icon="mdi-file-sign" text="Contratos totais gerados: No período" :corAvatar="coresAcademias.academais.corAvatar"/>
            </div>
          </template>
      </v-col>
     
 

      <!-- Card ACADEMIA 1 -->
      <v-col cols="12" md="4">
        <v-card class="pa-4">
          <v-card-title class="d-flex justify-center">{{silva}}</v-card-title>
          <v-card-text class="d-flex flex-column align-center">
            
            <GraficoRosca :chart-data="chartDataAcademia1" />
            <v-list>
              <v-list-item v-for="(item, index) in chartDataAcademia1.labels" :key="index">
                <v-list-item-icon>
                  <v-icon :style="{ color: chartDataAcademia1.datasets[0].backgroundColor[index] }">mdi-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item }} : {{ chartDataAcademia1.datasets[0].data[index] }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
          <template>
            <div>
              <!-- Chamando o componente CardInfo com diferentes valores -->
              <CardInfo :value="totalAlunosAcademia1" icon="mdi-account" text="Alunos: ativos" :corAvatar="coresAcademias.academia1.corAvatar"/>
              <CardInfo :value="ticketMedioAcademia1" icon="mdi-cash" text="Ticket Médio: ativos" :corAvatar="coresAcademias.academia1.corAvatar"/>
              <CardInfo :value="ltvAcademia1" icon="mdi-history" text="Ltv: ativos" :corAvatar="coresAcademias.academia1.corAvatar"/>
              <CardInfo :value="contratosAcademia1" icon="mdi-file-sign" text="Contratos gerados: No período" :corAvatar="coresAcademias.academia1.corAvatar"/>
            </div>
          </template>
      </v-col>

      <!-- Card ACADEMIA 2 -->
      <v-col cols="12" md="4">
        <v-card class="pa-4 mr-2">
          <v-card-title class="d-flex justify-center">Medianeira</v-card-title>
          <v-card-text class="d-flex flex-column align-center">
            
            <GraficoRosca :chart-data="chartDataAcademia2" />
            <v-list>
              <v-list-item v-for="(item, index) in chartDataAcademia2.labels" :key="index">
                <v-list-item-icon>
                  <v-icon :style="{ color: chartDataAcademia2.datasets[0].backgroundColor[index] }">mdi-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item }} : {{ chartDataAcademia2.datasets[0].data[index] }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
          <template>
            <div>
              <!-- Chamando o componente CardInfo com diferentes valores -->
              <CardInfo :value="totalAlunosAcademia2" icon="mdi-account" text="Alunos: ativos" :corAvatar="coresAcademias.academia2.corAvatar"/>
              <CardInfo :value="ticketMedioAcademia2" icon="mdi-cash" text="Ticket Médio: ativos" :corAvatar="coresAcademias.academia2.corAvatar"/>
              <CardInfo :value="ltvAcademia2" icon="mdi-history" text="Ltv: ativos" :corAvatar="coresAcademias.academia2.corAvatar"/>
              <CardInfo :value="contratosAcademia2" icon="mdi-file-sign" text="Contratos gerados: No período" :corAvatar="coresAcademias.academia2.corAvatar"/>
            </div>
          </template>
      </v-col>
    </v-row>
    </v-card>
        <!-- <v-card class="mt-5 ">
          <GraficoLinha :dados="this.chartLinhaContratosSilva" nomeLegenda="Contratos Silva" :corLinha="coresAcademias.academia1.corLinha"  />
        </v-card>
        <v-card class="mt-5 ">
          <GraficoLinha :dados="this.chartLinhaContratosMedianeira" nomeLegenda="Contratos Medianeira" :corLinha="coresAcademias.academia2.corLinha"  />
        </v-card> -->
        <v-card class="mt-5 ">
          <GraficoLinhaMulti :dados="[this.chartLinhaContratosSilva,this.chartLinhaContratosMedianeira]"  />
        </v-card>


      </v-col>
      <!-- <v-col cols="12">
        <v-card color="#FBFBFB" elevation="0">
          <v-card-title class="text-h4 font-weight-medium">
            <v-icon class="mr-2 text-h4">mdi-view-list</v-icon> {{ cardSelecionado }}
          </v-card-title>
          <v-card-text class="">
            <app-tabela v-if="alunos.length > 0" :alunos="alunos"></app-tabela>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
    <!-- <v-row>
                <v-col cols="12">
                    <v-select
                    v-model="selectedTrainer"
                    :items="trainers"
                    item-text="name"
                    item-value="id"
                    label="Selecione um Treinador"
                    @change="loadTrainerData"
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <v-card>
                    <v-card-title>Número de Alunos</v-card-title>
                    <v-card-text>
                        <h2>{{ trainerData.totalAlunos }}</h2>
                    </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    <v-card>
                    <v-card-title>Ticket Médio</v-card-title>
                    <v-card-text>
                        <h2>R$ {{ trainerData.ticketMedio.toFixed(2) }}</h2>
                    </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <v-card>
                    <v-card-title>Evolução dos Treinos</v-card-title>
                    <v-card-text>
                        <GraficoBarras :chart-data="chartData" />
                    </v-card-text>
                    </v-card>
                </v-col>
    </v-row> -->
  </v-container>
</template>

<script>

const COLORS = {
  ENTRADAS: "#A0D995",       // Verde claro pastel
  RENOVACOES: "#F5A17D",     // Laranja claro pastel
  TRANSFERENCIAS: "#82C4E8", // Vermelho claro pastel
  SAIDAS: "#FF8A80",        // Vinho claro pastel
  CONTRATOS: "#FF8F80"          // Vinho claro pastel
}
const coresAcademias = {
  academais: {
    corLinha: "#FFB6C1", // Rosa pastel
    corAvatar: "#FFB347", // Rosa mais claro para o avatar
  },
  academia1: {
    corLinha: "#FFB6C1", // Rosa pastel
    corAvatar: "#FFDEE9", // Rosa mais claro para o avatar
  },
  academia2: {
    corLinha: "#87CEFA", // Azul pastel
    corAvatar: "#BFEFFF", // Azul mais claro para o avatar
  },
};
const LABELS = ["Entradas", "Saídas", "Transferências", "Renovações"]
const BKGRDCOLOR = [COLORS.ENTRADAS, COLORS.SAIDAS, COLORS.TRANSFERENCIAS, COLORS.RENOVACOES]
import _relatoriosServico from "@/services/relatorios/relatorios-servico.js";
import util from "@/utils/utils.js";
// import GraficoBarras from "../AppGraficoBarra.vue"
import GraficoRosca from "../AppGraficoRosca.vue"
// import GraficoLinha from "../AppGraficoLinha.vue"
import GraficoLinhaMulti from "../AppGraficoLinhaMulti.vue"
import CardInfo from './CardInfo.vue'

export default {
  components: {
    // GraficoBarras,
    GraficoRosca,
    // GraficoLinha,
    GraficoLinhaMulti,
    CardInfo,
  },
  data() {
    return {
      alunos: [],
      listaDados: [],
      coresAcademias,
      chartLinhaContratosSilva: [],
      chartLinhaContratosMedianeira: [],

      chartDataTotal: {
        labels: LABELS,
        datasets: [{ 
          backgroundColor: BKGRDCOLOR, 
          data: [] 
        }]
      },
      chartDataAcademia1: {
        labels: LABELS,
        datasets: [{ 
          backgroundColor: BKGRDCOLOR, 
          data: [] 
        }]
      },
      chartDataAcademia2: {
        labels: LABELS,
        datasets: [{ 
          backgroundColor: BKGRDCOLOR, 
          data: [] 
        }]
      },

      data_inicial: null,
      data_final: null,
      situacao: null,

      dataInicial: null,
      menuDatePickerI: false,

      dataFinal: null,
      menuDatePickerF: false,

      ativarDatePicker: null,

      cardSelecionado: null,

      selectedTrainer: null,

      trainers: [
          { id: 1, name: 'João Silva' },
          { id: 2, name: 'Maria Oliveira' }
      ],

      trainerData: {
          totalAlunos: 0,
          ticketMedio: 0
      },

      chartData: {
          labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul'],
          datasets: [{ label: 'Aulas', data: [] }]
      }
    };
  },

  mounted() {
    // Criar uma nova data
    let dataAtual = new Date();

    // Definir o primeiro dia do mês
    let primeiroDia = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);

    // Definir o último dia do mês
    let ultimoDia = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0);

    this.data_inicial = util.converterFormatoPtBr(primeiroDia.toISOString());
    this.data_final = util.converterFormatoPtBr(ultimoDia.toISOString());
    
    this.listarEstatisticas();
  },
  watch: {
    dataInicial() {
      this.data_inicial = util.converterFormatoPtBr(this.dataInicial);
    },
    dataFinal() {
      this.data_final = util.converterFormatoPtBr(this.dataFinal);
    },
  },
  computed: {
    totalAlunos() {
      return this.listaDados.length ? this.listaDados[0].alunos.length + this.listaDados[1].alunos.length : 0
    },
    ltvTotal() {
      return this.listaDados.length ? (this.listaDados[0].ltv + this.listaDados[1].ltv)/2 : 0
    },
    contratoTotal(){
      return this.listaDados.length ? this.listaDados[0].entradas + this.listaDados[1].entradas + this.listaDados[0].renovacoes + this.listaDados[1].renovacoes: 0
    },

    ticketMedio() {
      return this.listaDados.length ? (this.listaDados[0].ticket_academia + this.listaDados[1].ticket_academia)/2 : 0
    },
    silva() {
      return 'Silva'
    },
    medianeira() {
      return 'Medianeira'
    },
    totalAlunosAcademia1() {
      return this.listaDados.length ? this.listaDados[0].alunos.length : 0
    },
    ltvAcademia1() {
      return this.listaDados.length ? this.listaDados[0].ltv : 0
    },
    contratosAcademia1(){
      return this.listaDados.length ? this.listaDados[0].entradas + this.listaDados[0].renovacoes : 0
    },
    ticketMedioAcademia1() {
      return this.listaDados.length ? this.listaDados[0].ticket_academia : 0
    },
    totalAlunosAcademia2() {
      return this.listaDados.length ? this.listaDados[1].alunos.length : 0
    },
    ltvAcademia2() {
      return this.listaDados.length ? this.listaDados[1].ltv : 0
    },
    contratosAcademia2(){
      return this.listaDados.length ? this.listaDados[1].entradas + this.listaDados[1].renovacoes : 0
    },
    ticketMedioAcademia2() {
      return this.listaDados.length ? this.listaDados[1].ticket_academia : 0
    },
    
  },
  methods: {
    loadTrainerData() {
            // Simulação de dados do treinador ao selecionar no dropdown
            const dataMock = {
                1: { totalAlunos: 25, ticketMedio: 150, aulas: [5, 10, 15, 20, 25, 30, 35] },
                2: { totalAlunos: 40, ticketMedio: 200, aulas: [10, 15, 20, 25, 30, 35, 40] }
            }

            this.trainerData = dataMock[this.selectedTrainer] || { totalAlunos: 0, ticketMedio: 0 }
            this.chartData.datasets[0].data = this.trainerData.aulas

        },
    listarEstatisticas() {
      var filtros = {
        data_de: util.formatarData(this.data_inicial),
        data_ate: util.formatarData(this.data_final),
        situacao: (this.situacao ? 2 : null)
      };
      _relatoriosServico.listarEstatisticas(filtros).then((response) => {
        this.listaDados = response;
        this.atualizarGrafico()

      });
    },
    atualizarGrafico() {
      if (!this.listaDados.length) return

      this.chartDataTotal.datasets[0].data = [
        this.listaDados[0].entradas + this.listaDados[1].entradas,
        this.listaDados[0].saidas + this.listaDados[1].saidas,
        this.listaDados[0].transferencias + this.listaDados[1].transferencias,
        this.listaDados[0].renovacoes + this.listaDados[1].renovacoes,

      ]
      this.chartLinhaContratosSilva =this.listaDados[0].contrato_mes
      this.chartLinhaContratosMedianeira =this.listaDados[1].contrato_mes

      this.chartDataAcademia1.datasets[0].data = [
        this.listaDados[0].entradas,
        this.listaDados[0].saidas,
        this.listaDados[0].transferencias,
        this.listaDados[0].renovacoes
      ]

      this.chartDataAcademia2.datasets[0].data = [
        this.listaDados[1].entradas,
        this.listaDados[1].saidas,
        this.listaDados[1].transferencias,
        this.listaDados[1].renovacoes
      ]
    },
    popularTabela(index = null, selecionado = "Todos") {
      this.cardSelecionado = selecionado
      this.alunos =
        index === null
          ? this.listaDados[0].alunos.concat(this.listaDados[1].alunos)
          : this.listaDados[index].alunos;
    },
  },
};
</script>

<style scoped>
.v-avatar .v-icon {
  color: white !important;
}
</style>