const tipoExperiencia = [
    { text: 'Agendado', value: 1 },
    { text: 'Reagendado', value: 2 },
    { text: 'Finalizado', value: 3 },
    { text: 'Convertido', value: 4 },
]

export default tipoExperiencia;


