<template>
  <div>
    <v-row class="align-center">
  <v-col cols="4">
    <v-text-field
      v-model="campoConsulta"
      prepend-inner-icon="mdi-magnify"
      label="Consultar"
      outlined
      dense

      clearable
    ></v-text-field>
  </v-col>

  <v-col cols="4">
    <v-select
      v-model="selectedAviso"
      :items="avisos"
      item-value="value"
    item-text="text"
      label="Filtrar por Status"
      outlined
      dense
      clearable
    ></v-select>
  </v-col>

  <v-col cols="4">
    <v-select
      v-model="selectedTreinador"
      :items="uniqueTreinadores"
      label="Filtrar por Treinador"
      outlined
      dense
      clearable
    ></v-select>
  </v-col>
</v-row>

    <v-data-table
      :headers="cabecalho"
      :items="filteredAlunos"
      class="elevation-0"
      :search="campoConsulta"
    >
    <template slot="item" slot-scope="{ item }">
        <tr :class="addClassAviso(item.aviso)">
          <td>{{ item.nome }}</td>
          <td>{{ item.treinador }}</td>
          <td >{{ item.situacao }}</td>
          <td >{{ item.data_inicio }}</td>
          <td>{{ item.data_vencimento }}</td>
          <td>{{ item.mes_atual }} de {{item.meses_totais }}</td>
        </tr>
      </template>
      </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    alunos: { required: false, type: Array, default: null },
  },
  data() {
    return {
      campoConsulta: null,
      selectedAviso: null,
      selectedTreinador: null,
      avisos: [
      { value: 1, text: "Vencidos" },
      { value: 2, text: "Não vencido - Mês atual" },
      { value: 3, text: "Vence no próximo mês" },
      { value: 4, text: "Vence nos próximos meses" },
    ],
      cabecalho: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
        },
        {
          text: "Treinador",
          align: "start",
          sortable: true,
          value: "treinador",
        },
        {
          text: "Situação",
          align: "start",
          sortable: true,
          value: "situacao",
        },
        {
          text: "Início",
          align: "start",
          sortable: true,
          value: "data_inicio",
        },
        {
          text: "Vencimento",
          align: "start",
          sortable: true,
          value: "data_vencimento",
        },
        {
          text: "Progresso Contrato",
          align: "start",
          sortable: true,
          value: "progresso_contrato",
        },
        // { text: "Entrada", sortable: false, value: "entradas" },
        // { text: "Renovação", sortable: false, value: "renovacoes" },
        // { text: "Transferência", sortable: false, value: "transferencias" },
        // { text: "Saída", sortable: false, value: "saidas" },
        // { text: "Local", sortable: false, value: "local" },
      ],
    };
  },
  computed: {
    uniqueTreinadores() {
    return [...new Set(this.alunos.map(aluno => aluno.treinador))]
      .filter(Boolean) // Remove valores nulos/vazios
      .sort((a, b) => a.localeCompare(b)); // Ordena em ordem crescente (A-Z)
  },
    filteredAlunos() {
      // Filtra os alunos com base na seleção e na pesquisa
      return this.alunos.filter((aluno) => {
        const matchesAviso = this.selectedAviso
          ? aluno.aviso === this.selectedAviso // Supondo que `aviso` é a propriedade do aluno
          : true;
        const matchesSearch = this.campoConsulta
          ? aluno.nome.toLowerCase().includes(this.campoConsulta.toLowerCase())
          : true;
        const matchTreinador = !this.selectedTreinador || aluno.treinador === this.selectedTreinador;

        return matchesAviso && matchesSearch && matchTreinador;
      });
    }
  },
  methods: {
    addClassAviso(aviso) {
      switch (aviso) {
        case 1:
          return "aviso-vermelho";
        case 2:
          return "aviso-verde";
        case 3:
          return "aviso-amarelo";
        default:
          return "";
      }
    },
  },
};
</script>
<style scoped>
.aviso-vermelho {
  background-color: #ffcccc !important; /* Vermelho claro */
  color: #b71c1c; /* Texto vermelho escuro */
}

.aviso-amarelo {
  background-color: #fff3cd !important; /* Amarelo claro */
  color: #856404; /* Texto amarelo escuro */
}

.aviso-verde {
  background-color: #d4edda !important; /* Verde claro */
  color: #155724; /* Texto verde escuro */
}
</style>
