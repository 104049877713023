const tipoMotivoSaida = [
    { text: 'Insatisfação com o treinador', value: 1 },
    { text: 'Questões financeiras', value: 2 },
    { text: 'Problemas pessoais', value: 3 },
    { text: 'Gestão de Tempo', value: 4 },
    { text: 'Sem motivo', value: 5 },
    { text: 'Mudança de cidade', value: 6 },
    { text: 'Outro', value: 7 },
]

export default tipoMotivoSaida;