import axios from "axios";

const utils = {
    /**
    * Formata data 
    * 
    * */
    
    formatarDataBanco(data) {
        const [year, month, day] = data.split("-");
        const newDate = `${month}/${day}/${year}`
        return new Date(newDate).toISOString().replace(/Z/, '');
    },
    formatarData(data) {
        const [day, month, year] = data.split("/");
        const newDate = `${month}/${day}/${year}`
        return new Date(newDate).toISOString().replace(/Z/, '');
    },

    formatarDataTela(data) {
        const [year, month, day] = data.substring(0, 10).split("-");
        const newDate = `${day}/${month}/${year}`
        return newDate;
    },

    diaSemana(dia) {
        if (dia === 1) {
            return "Segunda-feira"
        } else if (dia === 2) {
            return "Terça-feira"
        } else if (dia === 3) {
            return "Quarta-feira"
        } else if (dia === 4) {
            return "Quinta-feira"
        } else if (dia === 5) {
            return "Sexta-feira"
        } else if (dia === 6) {
            return "Sábado"
        } else if (dia === 7) {
            return "Domingo"
        }
    },

    /**
     * Converte o numero do mês para a string
     * @param {int} numeroMes 1
     * @param {bool} abreviado false
     * @returns se abreviado for true retorna Jan. , se não, retorna Janeiro
     */
    retornaStringMes(numeroMes, abreviado = false) {
        const data = new Date(2023, 1, 1)
        data.setMonth(numeroMes - 1) // O mês em JavaScript começa em 0 (Janeiro = 0, Fevereiro = 1, etc.)

        return data.toLocaleString("pt-BR", { month: abreviado ? "short" : "long" })
    },

    formataHora(hora) {
        return hora.substring(0, 5)
    },
/**
   * Converte a data atual para o formato EnUS
   * @returns 2022-03-03
   */
converterFormatoEnUs() {
    return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10)
  },

  /**
   * ENTRADA
   * 2021-12-31 00:00:00 ou 2021-12-31T00:00:00
   * @param {String} date data em String ou Date()
   * @returns 31/12/2021
   */
  converterFormatoPtBr(date) {
    if (!date) {
      return
    }

    const [ano, mes, dia] = date.split("-")

    return `${date.includes("T") ? dia.split("T")[0] : dia.split(" ")[0]}/${mes}/${ano.split(" ")[0]}`
  },

  /**
   * ENTRADA
   * {31/12/2021 00:00:00 ou 31/12/2021T00:00:00} /
   * {31-12-2021 00:00:00 ou 31-12-2021T00:00:00}
   * @param {String} date data em string ou Date()
   * @param {String} split por / ou -
   * @param {Boolean} hora retorna a data caso for false, se true retorna a data
   * @returns hora: false (31/12/2021) / hora: true (00:00)
  */
  converterParaDataCurta(date, split = "/", hora = false) {
    if (!date) {
      return
    }
    var splitDivisor = date.includes("T") ? "T" : " "

    if (hora) {
      const [hora, minuto] = date.split(split)[2].split(splitDivisor)[1].split(":")

      return `${hora}:${minuto}`
    }

    const [dia, mes, ano] = date.split(split)

    return `${dia}/${mes}/${ano.split(splitDivisor)[0]}`
  },

    sleep(ms) {
        return new Promise(
            resolve => setTimeout(resolve, ms)
        );
    },

    removerAcentos(texto) {
        return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },

    /**
     * Método para baixar arquivos do FTP e por base64
     * Ação de retorno é o download do arquivo em si.
     *
     * os parâmetros são opcionais, o download é feito pelo caminho ou pela base64
     *
     * @param {string} caminho = http://127.0.0.1:8081/Edital/4e98d257-e5cc-4e5a-87ee-a8f0c9c83408.jpg
     * @param {base64} base64 = string em base64. OBS: é necessário passar o (data:application/pdf;base64,) para funcionar corretamente.
     *
     */

    baixarArquivo(caminho = null, base64 = null, nomeArquivo = null) {
        if (caminho !== null && base64 === null) {
            axios({
                url: caminho,
                method: "GET",
                responseType: "blob",
            }).then((response) => {
                gerarLinkDownload(
                    nomeArquivo === null ? caminho.split("/").pop() : nomeArquivo,
                    new Blob([response.data]),
                    false
                )
            })
        } else {
            gerarLinkDownload(
                nomeArquivo === null ? Math.random().toString(36).slice(2) : nomeArquivo,
                base64,
                true
            )
        }
    },

    /**
     * Método para setar para a área de transferência
     * Exemplo
     *  <v-btn
            id="idCodigoBoleto" //esse id é o mesmo que é passado por paramêtro                        
            type="button"
            @click="util.copiarAreaTransferencia('idCodigoBoleto', '34191.75306 36579.502042 00173.090002 2 000')"
     * @param {String} id // id o elemento clicado
     * @param {String} valor // valor que será copiado para área de transferência
     */
    async copiarAreaTransferencia(id, valor) {
        console.log(id)
        console.log(valor)
        var elemento = document.getElementById(id)
        console.log(elemento)
        await navigator.clipboard.writeText(valor)
        const html = `<div id='mensagemCopiar' class='mensagem-copiar mostrar d-flex align-center'>
                                Copiado para área de transferência. 
                                <i class='mdi mdi-check-all green--text text-h4 ml-4'/>
                        </div>`

        elemento.insertAdjacentHTML("beforeend", html)

        const mensagemCopiar = document.querySelectorAll("#mensagemCopiar")
        setTimeout(() => {
            mensagemCopiar.forEach(x => x.remove())
        }, "2700")
    },

    formataNomeCompleto(nomeCompleto) {
        const partesDoNome = nomeCompleto.split(" ");

        if (partesDoNome.length === 1) {
            // Se há apenas um nome, retorne o nome completo.
            return nomeCompleto;
        } else {
            // Caso contrário, pegue o primeiro nome e o último sobrenome.
            const primeiroNome = partesDoNome[0];
            const ultimoSobrenome = partesDoNome[partesDoNome.length - 1];
            const inicialUltimoSobrenome = ultimoSobrenome[0];

            // Retorne o primeiro nome e o último sobrenome.
            return `${primeiroNome} ${inicialUltimoSobrenome}.`;
        }
    },

    /**
     * entrada = 1234.56
     * retorno = R$ 1.234,56
     */
    formatarDinheiro(valor) {
        if (valor === null || valor === undefined) {
            valor = 0
        }
        return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    },

}

function gerarLinkDownload(nomeArquivo, data, ehBase) {
    var fileURL = ehBase
        ? data
        : window.URL.createObjectURL(data)

    var fileLink = document.createElement("a")

    fileLink.href = fileURL
    fileLink.setAttribute("download", ehBase ? nomeArquivo + `.${data.split(";")[0].split("/")[1]}` : nomeArquivo)
    document.body.appendChild(fileLink)

    fileLink.click()
}

// function gerarBase64(valor){
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader()
//         reader.readAsDataURL(valor)
//         reader.onload = () => resolve(reader.result)
//         reader.onerror = error => reject(error)
// })
// }

export default utils