<template>
  <div class="calendario-semanal" style="overflow-x: auto">
    <!-- <v-col cols="12">
      <v-breadcrumbs
        class="float-right"
        :items="items"
        divider="/"
      ></v-breadcrumbs>
    </v-col> -->
    <app-carregando v-if="carregando" />
    <v-container>
  <v-row justify="center">
    <v-col cols="12" md="12">
      <div class="tabela-container">
      <table class="tabela-agenda" style="">
        <thead>
          <tr>
            <th style="background-color: white">
              <v-btn icon @click="gerenciarSemana('-')"
                ><v-icon>mdi mdi-chevron-left</v-icon></v-btn
              >
            </th>
            <th v-for="(dia, i) in dias" :key="dia">
              <span :class="{ hoje: isToday(dia) }">
                {{ semanaAtual[i] }}
                <br />
                <small>
                  {{ dia }}
                </small>
              </span>
            </th>
            <th style="background-color: white">
              <v-btn icon @click="gerenciarSemana('+')"
                ><v-icon>mdi mdi-chevron-right</v-icon></v-btn
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="horario in horarios" :key="horario">
            <td style="width: 40px">{{ horario.substring(0, 2) + "h" }}</td>
            <td style="min-width: 3px" v-for="(dia, index) in dias" :key="dia">
              <div v-if="alunos.length > 0" class="d-inline-block ma-2">
                <v-chip
                  label
                  v-for="(item, i) in listarAlunos(index, horario)"
                  :key="i"
                  class="ma-1"
                  :color="item.recupera ? 'orange' : 'gray'"
                >
                  {{ formataNome(item.nome) }}
                  <v-tooltip top v-if="!item.recupera">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        icon
                        class="ml-2"
                        v-if="!verificarReferencia(item.id_horario)"
                      >
                        <v-icon small @click="abreModal(item, dia, horario)"
                          >mdi-pencil</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Editar horário</span>
                  </v-tooltip>
                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        class="ma-1"
                        @click="deletar(item)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </v-chip>
              </div>
            </td>
          </tr>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-toolbar color="primary" dark
                >{{ titulo }}
                <v-spacer></v-spacer>
                <v-btn icon @click="fechaModal()">
                  <v-icon>mdi mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text class="mt-4">
                <v-row>
                  <v-col cols="12" v-if="mensagem">
                    <v-alert type="warning" dismissible>{{ mensagem }}</v-alert>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="dialogoDia"
                      :items="dias"
                      hide-details=""
                      label="Dia semana"
                      outlined
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="dialogoHora"
                      :items="horarios"
                      hide-details=""
                      label="Horário"
                      outlined
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="dialogoTreinador"
                      :items="listaTreinadores"
                      hide-details=""
                      item-text="nome"
                      item-value="id"
                      label="Treinadores"
                      outlined
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text color="error" @click="fechaModal()"> Cancelar </v-btn>
                <v-btn color="primary" @click="salvar()"
                  >Salvar
                  <v-icon class="ml-2">mdi mdi-content-save</v-icon></v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </tbody>
      </table>
      </div>
    </v-col>
  </v-row>
  </v-container>
  </div>
</template>

<script>
// import _utils from "@/utils/utils.js";

import _treinador from "@/services/treinador/treinador-servico.js";
import _alunos from "@/services/aluno/aluno-servico.js";
import _aula from "@/services/aula/aula-servico.js";

import Aula from "@/models/aula.js";

import AppCarregando from "@/components/AppCarregando.vue";

export default {
  components: {
    AppCarregando,
  },
  data() {
    return {
      treinadorId: parseInt(this.$route.params.id),
      // items: [
      //   {
      //     text: "Inicial",
      //     disabled: false,
      //     href: "/dashboard",
      //   },
      //   {
      //     text: "Treinadores",
      //     disabled: false,
      //     href: "/treinadores",
      //   },
      //   {
      //     text: "Agenda de horários dos alunos",
      //     disabled: true,
      //   },
      // ],
      Aula: new Aula(),

      carregando: false,
      nome1: "",
      nome2: "",
      dias: ["Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
      horarios: [
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
      ],
      eventos: [],
      dialog: false,
      titulo: "",
      alunos: [],
      listaTreinadores: [],

      dialogoDia: null,
      dialogoHora: null,
      dialogoAluno: null,
      dialogoHorario: null,
      dialogoTreinador: null,

      mensagem: null,

      semanaAtual: [],
      semana: 0,
    };
  },
  async mounted() {
    this.gerenciarSemana();
    await this.gerenciarAlunosTreinador();
  },
  methods: {
    async gerenciarAlunosTreinador() {
      this.carregando = true;

      var i = 0;
      var iRetorno = 0;
      this.alunos = [];

      this.dias.forEach((dia, index) => {
        this.horarios.forEach(async (hora) => {
          i++;
          const data = `${this.converterFormatoEnUs(
            this.semanaAtual[index]
          )} ${hora}`;

          await _alunos
            .buscarAlunosTreinadorData(this.treinadorId, index + 1, hora, data)
            .then((x) => {
              this.alunos.push({
                dia: index + 1,
                hora: hora,
                aluno: x,
              });

              iRetorno++;

              if (i === iRetorno) {
                this.carregando = false;
              }
            });
        });
      });
    },

    salvar() {
      if (this.dialogoDia && this.dialogoHora) {
        this.carregando = true
        const data = this.converterFormatoEnUs(
          this.semanaAtual[this.dias.findIndex((x) => x === this.dialogoDia)]
        );

        this.Aula.data = `${data} ${this.dialogoHora}`;
        this.Aula.aluno_id = this.dialogoAluno;
        this.Aula.treinador_id = this.dialogoTreinador;
        this.Aula.presente = false;
        this.Aula.recuperada = true;
        this.Aula.situacao = this.dialogoHorario;

        _aula.gravarAula(this.Aula);

        this.fechaModal();
        
        setTimeout(() => {
            this.gerenciarAlunosTreinador();
        }, 1000);
      } else {
        this.mensagem = "Informe todos os campos!!!";

        setTimeout(() => {
          this.mensagem = null;
        }, 2000);
      }
    },

    converterFormatoEnUs(data) {
      const [dia, mes, ano] = data.split("/");
      return `${ano}-${mes}-${dia}`;
    },

    listarAlunos(index, horario) {
      const aluno = this.alunos.filter(
        (x) => x.dia === index + 1 && x.hora === horario
      );
      return aluno.length > 0 ? aluno[0].aluno : null;
    },

    getEventosParaDia(dia, horario) {
      return this.eventos.filter(
        (evento) => evento.dia === dia && evento.horario === horario
      );
    },

    isToday(dia) {
      const hoje = new Date()
        .toLocaleString("pt-BR", { weekday: "long" })
        .split("-")[0]
        .trim();
      return dia.toLowerCase() === hoje.toLowerCase();
    },

    formataNome(nome) {
      return nome.length > 15 ?nome.substring(0, 15) + '...' :nome 
    },

    inicialNome(nome) {
      return nome[0];
    },

    gerenciarSemana(acao = null) {
      this.semana =
        acao !== null
          ? acao === "+"
            ? this.semana + 1
            : this.semana - 1
          : this.semana;

      const currentWeek = this.gerarSemana(this.semana);

      this.semanaAtual = currentWeek.map((date) => date.toLocaleDateString());

      this.gerenciarAlunosTreinador();
    },

    gerarSemana(weekOffset = 0) {
      const today = new Date();
      const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

      // Calculate the number of days to subtract to get to Monday of the current week
      const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

      // Create the base Monday date of the current week
      const mondayDate = new Date(
        today.getTime() - daysToSubtract * 24 * 60 * 60 * 1000
      );

      // Calculate the target Monday date by adding or subtracting the weekOffset
      const targetMondayDate = new Date(
        mondayDate.getTime() + weekOffset * 7 * 24 * 60 * 60 * 1000
      );

      // Create an array to store the week's dates
      const week = [];

      // Add dates from Monday to Saturday to the array
      for (let i = 0; i < 7; i++) {
        const currentDayDate = new Date(
          targetMondayDate.getTime() + i * 24 * 60 * 60 * 1000
        );
        week.push(currentDayDate);
      }

      return week;
    },

    verificarReferencia(id_horario) {
      const alunosComReferencia = this.alunos.flatMap((diaObj) =>
        diaObj.aluno.filter(
          (aluno) => aluno.recupera && aluno.referencia_dia === id_horario
        )
      );

      return alunosComReferencia.length > 0;
    },

    async abreModal(item, dia, horario) {
      [console.log(item)];
      this.dialogoAluno = item.id;
      this.dialogoHorario = item.id_horario;
      this.dialogoTreinador = this.treinadorId;

      this.titulo = item.nome + " | " + dia + " | " + horario;
      this.dialog = true;

      _treinador
        .listarTreinadoresAtivos()
        .then((x) => (this.listaTreinadores = x));
    },

    deletar(item) {
      console.log(item);

      _aula.deletarAula(item.id_aula).then(() => {
        this.gerenciarAlunosTreinador();
      });
    },

    buscaAlunos(id, dia, horario) {
      return _alunos.buscarAlunosTreinadorDia(id, dia, horario);
    },

    fechaModal() {
      this.dialog = false;
      this.limparCampos();
    },

    limparCampos() {
      this.titulo = "";
      this.dialogoDia = null;
      this.dialogoHora = null;
      this.dialogoAluno = null;
      this.dialogoTreinador = null;
    },
  },
};
</script>

<style scoped>
.calendario-semanal {
  font-family: Arial, sans-serif;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  padding: 10px;
}

table th.hoje {
  background-color: #ffcc00;
}

table td {
  /* padding: 10px; */
  border: 1px solid #ccc;
}

.evento {
  background-color: #007bff;
  color: #fff;
  /* padding: 5px; */
  /* margin: 5px 0; */
  cursor: pointer;
}

.hoje {
  color: #ffcc00;
}

@media (max-width: 768px) {
  table {
    font-size: 12px;
  }

  .tabela-agenda {
  max-width: 1200px !important;
  margin: auto;
  border-collapse: collapse;
}

.tabela-container {
  overflow-x: auto; /* Permite rolar horizontalmente */
}

@media (max-width: 768px) {
  .tabela-agenda {
    display: block;
    overflow-x: auto;
    white-space: nowrap; /* Evita quebras */
  }

  .tabela-agenda thead {
    display: none; /* Esconde cabeçalhos em telas pequenas */
  }

  .tabela-agenda tbody,
  .tabela-agenda tr,
  .tabela-agenda td {
    display: block;
    width: 100%;
  }

  .tabela-agenda td {
    text-align: right;
    position: relative;
    padding-left: 50%;
  }

  .tabela-agenda td:before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    font-weight: bold;
  }
}

}
</style>