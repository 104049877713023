var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"titulo":"","destino":"dashboard"}}),_c('v-toolbar',{attrs:{"color":"orange","dense":""}},[_c('v-toolbar-title',[_vm._v("Treinadores")])],1),_c('v-divider'),_c('br'),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"col":"12","md":"12","xl":"12","xs":"12","sm":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Filtros ")]),_c('v-card-text'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue","dark":""}},[_vm._v("Buscar "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"color":"yellow","dark":""}},[_vm._v("Limpar "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-eraser")])],1),(_vm.admin==true)?_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"#FF562B","dark":""},on:{"click":function($event){return _vm.rota('cadastrousuarios')}}},[_vm._v("Cadastrar "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-plus")])],1):_vm._e()],1)],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"col":"12","md":"12","xl":"12","xs":"12","sm":"12"}},[_c('h3',{staticClass:"mb-2"},[_vm._v("Lista de treinadores")]),_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","clearable":"","label":"consultar","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.treinadores,"items-per-page":10,"search":_vm.search,"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'Itens por página',
            }},scopedSlots:_vm._u([{key:`item.cadastro`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.editarTreinador(item)}}},[_vm._v(" mdi-pencil-outline ")])]}},{key:"no-data",fn:function(){return [_c('v-spacer',[_c('div',[_c('br')])]),_c('v-alert',{attrs:{"value":true,"type":"info","icon":"mdi-alert-circle-outline","color":"warning","dark":""}},[_vm._v(" Nenhum treinador encontrado. ")])]},proxy:true}],null,true)})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }