<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'GraficoRosca',
  components: { Doughnut },
  props: {
    chartData: { 
      type: Object, 
      required: true 
    },
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 300
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
                // position: "bottom",
                //     labels: {
                //       generateLabels: (chart) => {
                //         let data = chart.data;
                //         if (data.labels.length && data.datasets.length) {
                //           return data.labels.map((label, index) => {
                //             let dataset = data.datasets[0];
                //             let value = dataset.data[index];

                //             return {
                //               text: `${label}: ${value}`, // Exibe o nome + valor
                //               fillStyle: dataset.backgroundColor[index],
                //               strokeStyle: dataset.borderColor ? dataset.borderColor[index] : dataset.backgroundColor[index],
                //               lineWidth: 1,
                //               hidden: isNaN(dataset.data[index]) || dataset.data[index] === null,
                //               index: index
                //             };
                //           });
                //         }
                //         return [];
                //       },
                //       padding: 30, // Aumenta o espaçamento interno das legendas
                //       usePointStyle: true, // Garante que o ícone seja redondo
                //     }
                }
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  let dataset = tooltipItem.dataset;
                  let total = dataset.data.reduce((acc, val) => acc + val, 0);
                  let value = dataset.data[tooltipItem.dataIndex];
                  let percentage = ((value / total) * 100).toFixed(1);
                  return `${value} (${percentage}%)`; // Exibe valor + percentual
                },
              },
            },
          }
          // chartOptions: {
          //   responsive: true,
          //   maintainAspectRatio: false,
          //   plugins: {
          //     legend: {
          //       position: "top",
          //       labels: {
          //         usePointStyle: true, // Deixa os pontos menores
          //       },
          //     },
          //     tooltip: {
          //       callbacks: {
          //         label: function (tooltipItem) {
          //           let dataset = tooltipItem.dataset;
          //           let total = dataset.data.reduce((acc, val) => acc + val, 0);
          //           let value = dataset.data[tooltipItem.dataIndex];
          //           let percentage = ((value / total) * 100).toFixed(1);
          //           return `${value} (${percentage}%)`; // Exibe valor + percentual
          //         },
          //       },
          //     },
          //   },
          // }
          // chartOptions: {
          //   responsive: true,
          //   maintainAspectRatio: false,
          //   plugins: {
          //     datalabels: {
          //       color: "#fff", // Cor do número dentro da rosca
          //       font: {
          //         weight: "bold",
          //         size: 14,
          //       },
          //       formatter: (value) => value, // Mostra o valor direto
          //     },
          //   },
          // },
    }
  }
}
</script>
