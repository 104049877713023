<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'GraficoLinha',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 450
    },
    height: {
      type: Number,
      default: 450
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    dados: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartData: {
        labels: this.labels, // Janeiro, Fevereiro, etc.
        datasets: [
          {
            label: 'Contratos Silva',
            backgroundColor: '#FFB6C1', // Laranja pastel
            borderColor: '#FFB6C1',
            data: this.dados[0], // Dados da primeira academia
            fill: false
          },
          {
            label: 'Contratos Medianeira',
            backgroundColor: '#87CEFA', // Azul pastel
            borderColor: '#87CEFA',
            data: this.dados[1], // Dados da segunda academia
            fill: false
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },

    watch: {
  dados: {
    immediate: true,
    deep: true,
    handler(novosDados) {
      if (!Array.isArray(novosDados) || novosDados.length < 2) {
        return; // Se não houver dados suficientes, não faz nada
      }

      // Converte cada academia separadamente
      const dadosTratados = novosDados.map((dado) => {
        if (typeof dado === "string") {
          return dado.split(",").map(Number); // 🔥 Converte string para array de números
        }
        return Array.isArray(dado) ? dado : []; // Garante que seja array
      });

      // Atualiza os datasets no gráfico
      this.chartData.datasets[0].data = dadosTratados[0]; // Academia A
      this.chartData.datasets[1].data = dadosTratados[1]; // Academia B

      // Gera os labels automaticamente com base na quantidade de meses
      this.chartData.labels = this.gerarLabels(dadosTratados[0].length);
    },
  },
},

  methods: {
    gerarLabels(qtdMeses) {
      const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
      return meses.slice(0, qtdMeses);
    },
  },
};
</script>
