<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: "line-chart",
    },
    nomeLegenda: {
      type: String,
      default: "Matrículas",
    },
    corLinha: {
      type: String,
      default: "blue",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 450,
    },
    height: {
      type: Number,
      default: 450,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    dados: { // ✅ Recebendo os dados via props
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: this.nomeLegenda,
            backgroundColor: this.corLinha,
            borderColor: this.corLinha,
            fill: false,
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
          backgroundColor: this.corLinha, // Cor de fundo do gráfico
        },
      },
    };
  },
  watch: {
  dados: {
    immediate: true,
    handler(novosDados) {
      if (typeof novosDados === "string") {
        novosDados = novosDados.split(",").map(Number); // 🔥 Converte para array de números
      }

      if (Array.isArray(novosDados) && novosDados.length) {
        this.chartData.datasets[0].data = novosDados;
        this.chartData.labels = this.gerarLabels(novosDados.length);
      }
    },
  },
},
  methods: {
    gerarLabels(qtdMeses) {
      const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
      return meses.slice(0, qtdMeses);
    },
  },
};
</script>
